@font-face {
  font-family: 'IcoFont';
  src: url('../fonts/icofont.eot');
  src:
    url('../fonts/icofont.eot?#iefix') format('embedded-opentype'),
    url('../fonts/icofont.woff') format('woff'),
    url('../fonts/icofont.ttf') format('truetype'),
    url('../fonts/icofont.svg') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^='icofont-'],
[class*=' icofont-'] {
  font-family: 'IcoFont' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
}

.icofont-angry-monster:before {
  content: '\e800';
}

.icofont-bathtub:before {
  content: '\e801';
}

.icofont-bird-wings:before {
  content: '\e802';
}

.icofont-bow:before {
  content: '\e803';
}

.icofont-castle:before {
  content: '\e804';
}

.icofont-circuit:before {
  content: '\e805';
}

.icofont-crown-king:before {
  content: '\e806';
}

.icofont-crown-queen:before {
  content: '\e807';
}

.icofont-dart:before {
  content: '\e808';
}

.icofont-disability-race:before {
  content: '\e809';
}

.icofont-diving-goggle:before {
  content: '\e80a';
}

.icofont-eye-open:before {
  content: '\e80b';
}

.icofont-flora-flower:before {
  content: '\e80c';
}

.icofont-flora:before {
  content: '\e80d';
}

.icofont-gift-box:before {
  content: '\e80e';
}

.icofont-halloween-pumpkin:before {
  content: '\e80f';
}

.icofont-hand-power:before {
  content: '\e810';
}

.icofont-hand-thunder:before {
  content: '\e811';
}

.icofont-king-monster:before {
  content: '\e812';
}

.icofont-love:before {
  content: '\e813';
}

.icofont-magician-hat:before {
  content: '\e814';
}

.icofont-native-american:before {
  content: '\e815';
}

.icofont-owl-look:before {
  content: '\e816';
}

.icofont-phoenix:before {
  content: '\e817';
}

.icofont-robot-face:before {
  content: '\e818';
}

.icofont-sand-clock:before {
  content: '\e819';
}

.icofont-shield-alt:before {
  content: '\e81a';
}

.icofont-ship-wheel:before {
  content: '\e81b';
}

.icofont-skull-danger:before {
  content: '\e81c';
}

.icofont-skull-face:before {
  content: '\e81d';
}

.icofont-snowmobile:before {
  content: '\e81e';
}

.icofont-space-shuttle:before {
  content: '\e81f';
}

.icofont-star-shape:before {
  content: '\e820';
}

.icofont-swirl:before {
  content: '\e821';
}

.icofont-tattoo-wing:before {
  content: '\e822';
}

.icofont-throne:before {
  content: '\e823';
}

.icofont-tree-alt:before {
  content: '\e824';
}

.icofont-triangle:before {
  content: '\e825';
}

.icofont-unity-hand:before {
  content: '\e826';
}

.icofont-weed:before {
  content: '\e827';
}

.icofont-woman-bird:before {
  content: '\e828';
}

.icofont-bat:before {
  content: '\e829';
}

.icofont-bear-face:before {
  content: '\e82a';
}

.icofont-bear-tracks:before {
  content: '\e82b';
}

.icofont-bear:before {
  content: '\e82c';
}

.icofont-bird-alt:before {
  content: '\e82d';
}

.icofont-bird-flying:before {
  content: '\e82e';
}

.icofont-bird:before {
  content: '\e82f';
}

.icofont-birds:before {
  content: '\e830';
}

.icofont-bone:before {
  content: '\e831';
}

.icofont-bull:before {
  content: '\e832';
}

.icofont-butterfly-alt:before {
  content: '\e833';
}

.icofont-butterfly:before {
  content: '\e834';
}

.icofont-camel-alt:before {
  content: '\e835';
}

.icofont-camel-head:before {
  content: '\e836';
}

.icofont-camel:before {
  content: '\e837';
}

.icofont-cat-alt-1:before {
  content: '\e838';
}

.icofont-cat-alt-2:before {
  content: '\e839';
}

.icofont-cat-alt-3:before {
  content: '\e83a';
}

.icofont-cat-dog:before {
  content: '\e83b';
}

.icofont-cat-face:before {
  content: '\e83c';
}

.icofont-cat:before {
  content: '\e83d';
}

.icofont-cow-head:before {
  content: '\e83e';
}

.icofont-cow:before {
  content: '\e83f';
}

.icofont-crab:before {
  content: '\e840';
}

.icofont-crocodile:before {
  content: '\e841';
}

.icofont-deer-head:before {
  content: '\e842';
}

.icofont-dog-alt:before {
  content: '\e843';
}

.icofont-dog-barking:before {
  content: '\e844';
}

.icofont-dog:before {
  content: '\e845';
}

.icofont-dolphin:before {
  content: '\e846';
}

.icofont-duck-tracks:before {
  content: '\e847';
}

.icofont-eagle-head:before {
  content: '\e848';
}

.icofont-eaten-fish:before {
  content: '\e849';
}

.icofont-elephant-alt:before {
  content: '\e84a';
}

.icofont-elephant-head-alt:before {
  content: '\e84b';
}

.icofont-elephant-head:before {
  content: '\e84c';
}

.icofont-elephant:before {
  content: '\e84d';
}

.icofont-elk:before {
  content: '\e84e';
}

.icofont-fish-1:before {
  content: '\e84f';
}

.icofont-fish-2:before {
  content: '\e850';
}

.icofont-fish-3:before {
  content: '\e851';
}

.icofont-fish-4:before {
  content: '\e852';
}

.icofont-fish-5:before {
  content: '\e853';
}

.icofont-fish:before {
  content: '\e854';
}

.icofont-fox-alt:before {
  content: '\e855';
}

.icofont-fox:before {
  content: '\e856';
}

.icofont-frog-tracks:before {
  content: '\e857';
}

.icofont-frog:before {
  content: '\e858';
}

.icofont-froggy:before {
  content: '\e859';
}

.icofont-giraffe-head-1:before {
  content: '\e85a';
}

.icofont-giraffe-head-2:before {
  content: '\e85b';
}

.icofont-giraffe-head:before {
  content: '\e85c';
}

.icofont-giraffe:before {
  content: '\e85d';
}

.icofont-goat-head:before {
  content: '\e85e';
}

.icofont-gorilla:before {
  content: '\e85f';
}

.icofont-hen-tracks:before {
  content: '\e860';
}

.icofont-horse-head-1:before {
  content: '\e861';
}

.icofont-horse-head-2:before {
  content: '\e862';
}

.icofont-horse-head:before {
  content: '\e863';
}

.icofont-horse-tracks:before {
  content: '\e864';
}

.icofont-jellyfish:before {
  content: '\e865';
}

.icofont-kangaroo:before {
  content: '\e866';
}

.icofont-lemur:before {
  content: '\e867';
}

.icofont-lion-head-1:before {
  content: '\e868';
}

.icofont-lion-head-2:before {
  content: '\e869';
}

.icofont-lion-head:before {
  content: '\e86a';
}

.icofont-lion:before {
  content: '\e86b';
}

.icofont-monkey-2:before {
  content: '\e86c';
}

.icofont-monkey-3:before {
  content: '\e86d';
}

.icofont-monkey-face:before {
  content: '\e86e';
}

.icofont-monkey:before {
  content: '\e86f';
}

.icofont-octopus-alt:before {
  content: '\e870';
}

.icofont-octopus:before {
  content: '\e871';
}

.icofont-owl:before {
  content: '\e872';
}

.icofont-panda-face:before {
  content: '\e873';
}

.icofont-panda:before {
  content: '\e874';
}

.icofont-panther:before {
  content: '\e875';
}

.icofont-parrot-lip:before {
  content: '\e876';
}

.icofont-parrot:before {
  content: '\e877';
}

.icofont-paw:before {
  content: '\e878';
}

.icofont-pelican:before {
  content: '\e879';
}

.icofont-penguin:before {
  content: '\e87a';
}

.icofont-pig-face:before {
  content: '\e87b';
}

.icofont-pig:before {
  content: '\e87c';
}

.icofont-pigeon-1:before {
  content: '\e87d';
}

.icofont-pigeon-2:before {
  content: '\e87e';
}

.icofont-pigeon:before {
  content: '\e87f';
}

.icofont-rabbit:before {
  content: '\e880';
}

.icofont-rat:before {
  content: '\e881';
}

.icofont-rhino-head:before {
  content: '\e882';
}

.icofont-rhino:before {
  content: '\e883';
}

.icofont-rooster:before {
  content: '\e884';
}

.icofont-seahorse:before {
  content: '\e885';
}

.icofont-seal:before {
  content: '\e886';
}

.icofont-shrimp-alt:before {
  content: '\e887';
}

.icofont-shrimp:before {
  content: '\e888';
}

.icofont-snail-1:before {
  content: '\e889';
}

.icofont-snail-2:before {
  content: '\e88a';
}

.icofont-snail-3:before {
  content: '\e88b';
}

.icofont-snail:before {
  content: '\e88c';
}

.icofont-snake:before {
  content: '\e88d';
}

.icofont-squid:before {
  content: '\e88e';
}

.icofont-squirrel:before {
  content: '\e88f';
}

.icofont-tiger-face:before {
  content: '\e890';
}

.icofont-tiger:before {
  content: '\e891';
}

.icofont-turtle:before {
  content: '\e892';
}

.icofont-whale:before {
  content: '\e893';
}

.icofont-woodpecker:before {
  content: '\e894';
}

.icofont-zebra:before {
  content: '\e895';
}

.icofont-brand-acer:before {
  content: '\e896';
}

.icofont-brand-adidas:before {
  content: '\e897';
}

.icofont-brand-adobe:before {
  content: '\e898';
}

.icofont-brand-air-new-zealand:before {
  content: '\e899';
}

.icofont-brand-airbnb:before {
  content: '\e89a';
}

.icofont-brand-aircell:before {
  content: '\e89b';
}

.icofont-brand-airtel:before {
  content: '\e89c';
}

.icofont-brand-alcatel:before {
  content: '\e89d';
}

.icofont-brand-alibaba:before {
  content: '\e89e';
}

.icofont-brand-aliexpress:before {
  content: '\e89f';
}

.icofont-brand-alipay:before {
  content: '\e8a0';
}

.icofont-brand-amazon:before {
  content: '\e8a1';
}

.icofont-brand-amd:before {
  content: '\e8a2';
}

.icofont-brand-american-airlines:before {
  content: '\e8a3';
}

.icofont-brand-android-robot:before {
  content: '\e8a4';
}

.icofont-brand-android:before {
  content: '\e8a5';
}

.icofont-brand-aol:before {
  content: '\e8a6';
}

.icofont-brand-apple:before {
  content: '\e8a7';
}

.icofont-brand-appstore:before {
  content: '\e8a8';
}

.icofont-brand-asus:before {
  content: '\e8a9';
}

.icofont-brand-ati:before {
  content: '\e8aa';
}

.icofont-brand-att:before {
  content: '\e8ab';
}

.icofont-brand-audi:before {
  content: '\e8ac';
}

.icofont-brand-axiata:before {
  content: '\e8ad';
}

.icofont-brand-bada:before {
  content: '\e8ae';
}

.icofont-brand-bbc:before {
  content: '\e8af';
}

.icofont-brand-bing:before {
  content: '\e8b0';
}

.icofont-brand-blackberry:before {
  content: '\e8b1';
}

.icofont-brand-bmw:before {
  content: '\e8b2';
}

.icofont-brand-box:before {
  content: '\e8b3';
}

.icofont-brand-burger-king:before {
  content: '\e8b4';
}

.icofont-brand-business-insider:before {
  content: '\e8b5';
}

.icofont-brand-buzzfeed:before {
  content: '\e8b6';
}

.icofont-brand-cannon:before {
  content: '\e8b7';
}

.icofont-brand-casio:before {
  content: '\e8b8';
}

.icofont-brand-china-mobile:before {
  content: '\e8b9';
}

.icofont-brand-china-telecom:before {
  content: '\e8ba';
}

.icofont-brand-china-unicom:before {
  content: '\e8bb';
}

.icofont-brand-cisco:before {
  content: '\e8bc';
}

.icofont-brand-citibank:before {
  content: '\e8bd';
}

.icofont-brand-cnet:before {
  content: '\e8be';
}

.icofont-brand-cnn:before {
  content: '\e8bf';
}

.icofont-brand-cocal-cola:before {
  content: '\e8c0';
}

.icofont-brand-compaq:before {
  content: '\e8c1';
}

.icofont-brand-debian:before {
  content: '\e8c2';
}

.icofont-brand-delicious:before {
  content: '\e8c3';
}

.icofont-brand-dell:before {
  content: '\e8c4';
}

.icofont-brand-designbump:before {
  content: '\e8c5';
}

.icofont-brand-designfloat:before {
  content: '\e8c6';
}

.icofont-brand-disney:before {
  content: '\e8c7';
}

.icofont-brand-dodge:before {
  content: '\e8c8';
}

.icofont-brand-dove:before {
  content: '\e8c9';
}

.icofont-brand-drupal:before {
  content: '\e8ca';
}

.icofont-brand-ebay:before {
  content: '\e8cb';
}

.icofont-brand-eleven:before {
  content: '\e8cc';
}

.icofont-brand-emirates:before {
  content: '\e8cd';
}

.icofont-brand-espn:before {
  content: '\e8ce';
}

.icofont-brand-etihad-airways:before {
  content: '\e8cf';
}

.icofont-brand-etisalat:before {
  content: '\e8d0';
}

.icofont-brand-etsy:before {
  content: '\e8d1';
}

.icofont-brand-fastrack:before {
  content: '\e8d2';
}

.icofont-brand-fedex:before {
  content: '\e8d3';
}

.icofont-brand-ferrari:before {
  content: '\e8d4';
}

.icofont-brand-fitbit:before {
  content: '\e8d5';
}

.icofont-brand-flikr:before {
  content: '\e8d6';
}

.icofont-brand-forbes:before {
  content: '\e8d7';
}

.icofont-brand-foursquare:before {
  content: '\e8d8';
}

.icofont-brand-foxconn:before {
  content: '\e8d9';
}

.icofont-brand-fujitsu:before {
  content: '\e8da';
}

.icofont-brand-general-electric:before {
  content: '\e8db';
}

.icofont-brand-gillette:before {
  content: '\e8dc';
}

.icofont-brand-gizmodo:before {
  content: '\e8dd';
}

.icofont-brand-gnome:before {
  content: '\e8de';
}

.icofont-brand-google:before {
  content: '\e8df';
}

.icofont-brand-gopro:before {
  content: '\e8e0';
}

.icofont-brand-gucci:before {
  content: '\e8e1';
}

.icofont-brand-hallmark:before {
  content: '\e8e2';
}

.icofont-brand-hi5:before {
  content: '\e8e3';
}

.icofont-brand-honda:before {
  content: '\e8e4';
}

.icofont-brand-hp:before {
  content: '\e8e5';
}

.icofont-brand-hsbc:before {
  content: '\e8e6';
}

.icofont-brand-htc:before {
  content: '\e8e7';
}

.icofont-brand-huawei:before {
  content: '\e8e8';
}

.icofont-brand-hulu:before {
  content: '\e8e9';
}

.icofont-brand-hyundai:before {
  content: '\e8ea';
}

.icofont-brand-ibm:before {
  content: '\e8eb';
}

.icofont-brand-icofont:before {
  content: '\e8ec';
}

.icofont-brand-icq:before {
  content: '\e8ed';
}

.icofont-brand-ikea:before {
  content: '\e8ee';
}

.icofont-brand-imdb:before {
  content: '\e8ef';
}

.icofont-brand-indiegogo:before {
  content: '\e8f0';
}

.icofont-brand-intel:before {
  content: '\e8f1';
}

.icofont-brand-ipair:before {
  content: '\e8f2';
}

.icofont-brand-jaguar:before {
  content: '\e8f3';
}

.icofont-brand-java:before {
  content: '\e8f4';
}

.icofont-brand-joomla:before {
  content: '\e8f5';
}

.icofont-brand-kickstarter:before {
  content: '\e8f6';
}

.icofont-brand-kik:before {
  content: '\e8f7';
}

.icofont-brand-lastfm:before {
  content: '\e8f8';
}

.icofont-brand-lego:before {
  content: '\e8f9';
}

.icofont-brand-lenovo:before {
  content: '\e8fa';
}

.icofont-brand-levis:before {
  content: '\e8fb';
}

.icofont-brand-lexus:before {
  content: '\e8fc';
}

.icofont-brand-lg:before {
  content: '\e8fd';
}

.icofont-brand-life-hacker:before {
  content: '\e8fe';
}

.icofont-brand-linux-mint:before {
  content: '\e8ff';
}

.icofont-brand-linux:before {
  content: '\e900';
}

.icofont-brand-lionix:before {
  content: '\e901';
}

.icofont-brand-loreal:before {
  content: '\e902';
}

.icofont-brand-louis-vuitton:before {
  content: '\e903';
}

.icofont-brand-mac-os:before {
  content: '\e904';
}

.icofont-brand-marvel-app:before {
  content: '\e905';
}

.icofont-brand-mashable:before {
  content: '\e906';
}

.icofont-brand-mazda:before {
  content: '\e907';
}

.icofont-brand-mcdonals:before {
  content: '\e908';
}

.icofont-brand-mercedes:before {
  content: '\e909';
}

.icofont-brand-micromax:before {
  content: '\e90a';
}

.icofont-brand-microsoft:before {
  content: '\e90b';
}

.icofont-brand-mobileme:before {
  content: '\e90c';
}

.icofont-brand-mobily:before {
  content: '\e90d';
}

.icofont-brand-motorola:before {
  content: '\e90e';
}

.icofont-brand-msi:before {
  content: '\e90f';
}

.icofont-brand-mts:before {
  content: '\e910';
}

.icofont-brand-myspace:before {
  content: '\e911';
}

.icofont-brand-mytv:before {
  content: '\e912';
}

.icofont-brand-nasa:before {
  content: '\e913';
}

.icofont-brand-natgeo:before {
  content: '\e914';
}

.icofont-brand-nbc:before {
  content: '\e915';
}

.icofont-brand-nescafe:before {
  content: '\e916';
}

.icofont-brand-nestle:before {
  content: '\e917';
}

.icofont-brand-netflix:before {
  content: '\e918';
}

.icofont-brand-nexus:before {
  content: '\e919';
}

.icofont-brand-nike:before {
  content: '\e91a';
}

.icofont-brand-nokia:before {
  content: '\e91b';
}

.icofont-brand-nvidia:before {
  content: '\e91c';
}

.icofont-brand-omega:before {
  content: '\e91d';
}

.icofont-brand-opensuse:before {
  content: '\e91e';
}

.icofont-brand-oracle:before {
  content: '\e91f';
}

.icofont-brand-panasonic:before {
  content: '\e920';
}

.icofont-brand-paypal:before {
  content: '\e921';
}

.icofont-brand-pepsi:before {
  content: '\e922';
}

.icofont-brand-philips:before {
  content: '\e923';
}

.icofont-brand-pizza-hut:before {
  content: '\e924';
}

.icofont-brand-playstation:before {
  content: '\e925';
}

.icofont-brand-puma:before {
  content: '\e926';
}

.icofont-brand-qatar-air:before {
  content: '\e927';
}

.icofont-brand-qvc:before {
  content: '\e928';
}

.icofont-brand-readernaut:before {
  content: '\e929';
}

.icofont-brand-redbull:before {
  content: '\e92a';
}

.icofont-brand-reebok:before {
  content: '\e92b';
}

.icofont-brand-reuters:before {
  content: '\e92c';
}

.icofont-brand-samsung:before {
  content: '\e92d';
}

.icofont-brand-sap:before {
  content: '\e92e';
}

.icofont-brand-saudia-airlines:before {
  content: '\e92f';
}

.icofont-brand-scribd:before {
  content: '\e930';
}

.icofont-brand-shell:before {
  content: '\e931';
}

.icofont-brand-siemens:before {
  content: '\e932';
}

.icofont-brand-sk-telecom:before {
  content: '\e933';
}

.icofont-brand-slideshare:before {
  content: '\e934';
}

.icofont-brand-smashing-magazine:before {
  content: '\e935';
}

.icofont-brand-snapchat:before {
  content: '\e936';
}

.icofont-brand-sony-ericsson:before {
  content: '\e937';
}

.icofont-brand-sony:before {
  content: '\e938';
}

.icofont-brand-soundcloud:before {
  content: '\e939';
}

.icofont-brand-sprint:before {
  content: '\e93a';
}

.icofont-brand-squidoo:before {
  content: '\e93b';
}

.icofont-brand-starbucks:before {
  content: '\e93c';
}

.icofont-brand-stc:before {
  content: '\e93d';
}

.icofont-brand-steam:before {
  content: '\e93e';
}

.icofont-brand-suzuki:before {
  content: '\e93f';
}

.icofont-brand-symbian:before {
  content: '\e940';
}

.icofont-brand-t-mobile:before {
  content: '\e941';
}

.icofont-brand-tango:before {
  content: '\e942';
}

.icofont-brand-target:before {
  content: '\e943';
}

.icofont-brand-tata-indicom:before {
  content: '\e944';
}

.icofont-brand-techcrunch:before {
  content: '\e945';
}

.icofont-brand-telenor:before {
  content: '\e946';
}

.icofont-brand-teliasonera:before {
  content: '\e947';
}

.icofont-brand-tesla:before {
  content: '\e948';
}

.icofont-brand-the-verge:before {
  content: '\e949';
}

.icofont-brand-thenextweb:before {
  content: '\e94a';
}

.icofont-brand-toshiba:before {
  content: '\e94b';
}

.icofont-brand-toyota:before {
  content: '\e94c';
}

.icofont-brand-tribenet:before {
  content: '\e94d';
}

.icofont-brand-ubuntu:before {
  content: '\e94e';
}

.icofont-brand-unilever:before {
  content: '\e94f';
}

.icofont-brand-vaio:before {
  content: '\e950';
}

.icofont-brand-verizon:before {
  content: '\e951';
}

.icofont-brand-viber:before {
  content: '\e952';
}

.icofont-brand-vodafone:before {
  content: '\e953';
}

.icofont-brand-volkswagen:before {
  content: '\e954';
}

.icofont-brand-walmart:before {
  content: '\e955';
}

.icofont-brand-warnerbros:before {
  content: '\e956';
}

.icofont-brand-whatsapp:before {
  content: '\e957';
}

.icofont-brand-wikipedia:before {
  content: '\e958';
}

.icofont-brand-windows:before {
  content: '\e959';
}

.icofont-brand-wire:before {
  content: '\e95a';
}

.icofont-brand-wordpress:before {
  content: '\e95b';
}

.icofont-brand-xiaomi:before {
  content: '\e95c';
}

.icofont-brand-yahoobuzz:before {
  content: '\e95d';
}

.icofont-brand-yamaha:before {
  content: '\e95e';
}

.icofont-brand-youtube:before {
  content: '\e95f';
}

.icofont-brand-zain:before {
  content: '\e960';
}

.icofont-bank-alt:before {
  content: '\e961';
}

.icofont-bank:before {
  content: '\e962';
}

.icofont-barcode:before {
  content: '\e963';
}

.icofont-bill-alt:before {
  content: '\e964';
}

.icofont-billboard:before {
  content: '\e965';
}

.icofont-briefcase-1:before {
  content: '\e966';
}

.icofont-briefcase-2:before {
  content: '\e967';
}

.icofont-businessman:before {
  content: '\e968';
}

.icofont-businesswoman:before {
  content: '\e969';
}

.icofont-chair:before {
  content: '\e96a';
}

.icofont-coins:before {
  content: '\e96b';
}

.icofont-company:before {
  content: '\e96c';
}

.icofont-contact-add:before {
  content: '\e96d';
}

.icofont-files-stack:before {
  content: '\e96e';
}

.icofont-handshake-deal:before {
  content: '\e96f';
}

.icofont-id-card:before {
  content: '\e970';
}

.icofont-meeting-add:before {
  content: '\e971';
}

.icofont-money-bag:before {
  content: '\e972';
}

.icofont-pie-chart:before {
  content: '\e973';
}

.icofont-presentation-alt:before {
  content: '\e974';
}

.icofont-presentation:before {
  content: '\e975';
}

.icofont-stamp:before {
  content: '\e976';
}

.icofont-stock-mobile:before {
  content: '\e977';
}

.icofont-chart-arrows-axis:before {
  content: '\e978';
}

.icofont-chart-bar-graph:before {
  content: '\e979';
}

.icofont-chart-flow-1:before {
  content: '\e97a';
}

.icofont-chart-flow-2:before {
  content: '\e97b';
}

.icofont-chart-flow:before {
  content: '\e97c';
}

.icofont-chart-growth:before {
  content: '\e97d';
}

.icofont-chart-histogram-alt:before {
  content: '\e97e';
}

.icofont-chart-histogram:before {
  content: '\e97f';
}

.icofont-chart-line-alt:before {
  content: '\e980';
}

.icofont-chart-line:before {
  content: '\e981';
}

.icofont-chart-pie-alt:before {
  content: '\e982';
}

.icofont-chart-pie:before {
  content: '\e983';
}

.icofont-chart-radar-graph:before {
  content: '\e984';
}

.icofont-architecture-alt:before {
  content: '\e985';
}

.icofont-architecture:before {
  content: '\e986';
}

.icofont-barricade:before {
  content: '\e987';
}

.icofont-bolt:before {
  content: '\e988';
}

.icofont-bricks:before {
  content: '\e989';
}

.icofont-building-alt:before {
  content: '\e98a';
}

.icofont-bull-dozer:before {
  content: '\e98b';
}

.icofont-calculations:before {
  content: '\e98c';
}

.icofont-cement-mix:before {
  content: '\e98d';
}

.icofont-cement-mixer:before {
  content: '\e98e';
}

.icofont-concrete-mixer:before {
  content: '\e98f';
}

.icofont-danger-zone:before {
  content: '\e990';
}

.icofont-drill:before {
  content: '\e991';
}

.icofont-eco-energy:before {
  content: '\e992';
}

.icofont-eco-environmen:before {
  content: '\e993';
}

.icofont-energy-air:before {
  content: '\e994';
}

.icofont-energy-oil:before {
  content: '\e995';
}

.icofont-energy-savings:before {
  content: '\e996';
}

.icofont-energy-solar:before {
  content: '\e997';
}

.icofont-energy-water:before {
  content: '\e998';
}

.icofont-engineer:before {
  content: '\e999';
}

.icofont-fire-extinguisher-alt:before {
  content: '\e99a';
}

.icofont-fire-extinguisher:before {
  content: '\e99b';
}

.icofont-fix-tools:before {
  content: '\e99c';
}

.icofont-fork-lift:before {
  content: '\e99d';
}

.icofont-glue-oil:before {
  content: '\e99e';
}

.icofont-hammer-alt:before {
  content: '\e99f';
}

.icofont-hammer:before {
  content: '\e9a0';
}

.icofont-help-robot:before {
  content: '\e9a1';
}

.icofont-industries-1:before {
  content: '\e9a2';
}

.icofont-industries-2:before {
  content: '\e9a3';
}

.icofont-industries-3:before {
  content: '\e9a4';
}

.icofont-industries-4:before {
  content: '\e9a5';
}

.icofont-industries-5:before {
  content: '\e9a6';
}

.icofont-industries:before {
  content: '\e9a7';
}

.icofont-labour:before {
  content: '\e9a8';
}

.icofont-mining:before {
  content: '\e9a9';
}

.icofont-paint-brush:before {
  content: '\e9aa';
}

.icofont-pollution:before {
  content: '\e9ab';
}

.icofont-power-zone:before {
  content: '\e9ac';
}

.icofont-radio-active:before {
  content: '\e9ad';
}

.icofont-recycle-alt:before {
  content: '\e9ae';
}

.icofont-recycling-man:before {
  content: '\e9af';
}

.icofont-safety-hat-light:before {
  content: '\e9b0';
}

.icofont-safety-hat:before {
  content: '\e9b1';
}

.icofont-saw:before {
  content: '\e9b2';
}

.icofont-screw-driver:before {
  content: '\e9b3';
}

.icofont-tools-1:before {
  content: '\e9b4';
}

.icofont-tools-bag:before {
  content: '\e9b5';
}

.icofont-tow-truck:before {
  content: '\e9b6';
}

.icofont-trolley:before {
  content: '\e9b7';
}

.icofont-trowel:before {
  content: '\e9b8';
}

.icofont-under-construction-alt:before {
  content: '\e9b9';
}

.icofont-under-construction:before {
  content: '\e9ba';
}

.icofont-vehicle-cement:before {
  content: '\e9bb';
}

.icofont-vehicle-crane:before {
  content: '\e9bc';
}

.icofont-vehicle-delivery-van:before {
  content: '\e9bd';
}

.icofont-vehicle-dozer:before {
  content: '\e9be';
}

.icofont-vehicle-excavator:before {
  content: '\e9bf';
}

.icofont-vehicle-trucktor:before {
  content: '\e9c0';
}

.icofont-vehicle-wrecking:before {
  content: '\e9c1';
}

.icofont-worker:before {
  content: '\e9c2';
}

.icofont-workers-group:before {
  content: '\e9c3';
}

.icofont-wrench:before {
  content: '\e9c4';
}

.icofont-afghani-false:before {
  content: '\e9c5';
}

.icofont-afghani-minus:before {
  content: '\e9c6';
}

.icofont-afghani-plus:before {
  content: '\e9c7';
}

.icofont-afghani-true:before {
  content: '\e9c8';
}

.icofont-afghani:before {
  content: '\e9c9';
}

.icofont-baht-false:before {
  content: '\e9ca';
}

.icofont-baht-minus:before {
  content: '\e9cb';
}

.icofont-baht-plus:before {
  content: '\e9cc';
}

.icofont-baht-true:before {
  content: '\e9cd';
}

.icofont-baht:before {
  content: '\e9ce';
}

.icofont-bitcoin-false:before {
  content: '\e9cf';
}

.icofont-bitcoin-minus:before {
  content: '\e9d0';
}

.icofont-bitcoin-plus:before {
  content: '\e9d1';
}

.icofont-bitcoin-true:before {
  content: '\e9d2';
}

.icofont-bitcoin:before {
  content: '\e9d3';
}

.icofont-dollar-flase:before {
  content: '\e9d4';
}

.icofont-dollar-minus:before {
  content: '\e9d5';
}

.icofont-dollar-plus:before {
  content: '\e9d6';
}

.icofont-dollar-true:before {
  content: '\e9d7';
}

.icofont-dollar:before {
  content: '\e9d8';
}

.icofont-dong-false:before {
  content: '\e9d9';
}

.icofont-dong-minus:before {
  content: '\e9da';
}

.icofont-dong-plus:before {
  content: '\e9db';
}

.icofont-dong-true:before {
  content: '\e9dc';
}

.icofont-dong:before {
  content: '\e9dd';
}

.icofont-euro-false:before {
  content: '\e9de';
}

.icofont-euro-minus:before {
  content: '\e9df';
}

.icofont-euro-plus:before {
  content: '\e9e0';
}

.icofont-euro-true:before {
  content: '\e9e1';
}

.icofont-euro:before {
  content: '\e9e2';
}

.icofont-frank-false:before {
  content: '\e9e3';
}

.icofont-frank-minus:before {
  content: '\e9e4';
}

.icofont-frank-plus:before {
  content: '\e9e5';
}

.icofont-frank-true:before {
  content: '\e9e6';
}

.icofont-frank:before {
  content: '\e9e7';
}

.icofont-hryvnia-false:before {
  content: '\e9e8';
}

.icofont-hryvnia-minus:before {
  content: '\e9e9';
}

.icofont-hryvnia-plus:before {
  content: '\e9ea';
}

.icofont-hryvnia-true:before {
  content: '\e9eb';
}

.icofont-hryvnia:before {
  content: '\e9ec';
}

.icofont-lira-false:before {
  content: '\e9ed';
}

.icofont-lira-minus:before {
  content: '\e9ee';
}

.icofont-lira-plus:before {
  content: '\e9ef';
}

.icofont-lira-true:before {
  content: '\e9f0';
}

.icofont-lira:before {
  content: '\e9f1';
}

.icofont-peseta-false:before {
  content: '\e9f2';
}

.icofont-peseta-minus:before {
  content: '\e9f3';
}

.icofont-peseta-plus:before {
  content: '\e9f4';
}

.icofont-peseta-true:before {
  content: '\e9f5';
}

.icofont-peseta:before {
  content: '\e9f6';
}

.icofont-peso-false:before {
  content: '\e9f7';
}

.icofont-peso-minus:before {
  content: '\e9f8';
}

.icofont-peso-plus:before {
  content: '\e9f9';
}

.icofont-peso-true:before {
  content: '\e9fa';
}

.icofont-peso:before {
  content: '\e9fb';
}

.icofont-pound-false:before {
  content: '\e9fc';
}

.icofont-pound-minus:before {
  content: '\e9fd';
}

.icofont-pound-plus:before {
  content: '\e9fe';
}

.icofont-pound-true:before {
  content: '\e9ff';
}

.icofont-pound:before {
  content: '\ea00';
}

.icofont-renminbi-false:before {
  content: '\ea01';
}

.icofont-renminbi-minus:before {
  content: '\ea02';
}

.icofont-renminbi-plus:before {
  content: '\ea03';
}

.icofont-renminbi-true:before {
  content: '\ea04';
}

.icofont-renminbi:before {
  content: '\ea05';
}

.icofont-riyal-false:before {
  content: '\ea06';
}

.icofont-riyal-minus:before {
  content: '\ea07';
}

.icofont-riyal-plus:before {
  content: '\ea08';
}

.icofont-riyal-true:before {
  content: '\ea09';
}

.icofont-riyal:before {
  content: '\ea0a';
}

.icofont-rouble-false:before {
  content: '\ea0b';
}

.icofont-rouble-minus:before {
  content: '\ea0c';
}

.icofont-rouble-plus:before {
  content: '\ea0d';
}

.icofont-rouble-true:before {
  content: '\ea0e';
}

.icofont-rouble:before {
  content: '\ea0f';
}

.icofont-rupee-false:before {
  content: '\ea10';
}

.icofont-rupee-minus:before {
  content: '\ea11';
}

.icofont-rupee-plus:before {
  content: '\ea12';
}

.icofont-rupee-true:before {
  content: '\ea13';
}

.icofont-rupee:before {
  content: '\ea14';
}

.icofont-taka-false:before {
  content: '\ea15';
}

.icofont-taka-minus:before {
  content: '\ea16';
}

.icofont-taka-plus:before {
  content: '\ea17';
}

.icofont-taka-true:before {
  content: '\ea18';
}

.icofont-taka:before {
  content: '\ea19';
}

.icofont-turkish-lira-false:before {
  content: '\ea1a';
}

.icofont-turkish-lira-minus:before {
  content: '\ea1b';
}

.icofont-turkish-lira-plus:before {
  content: '\ea1c';
}

.icofont-turkish-lira-true:before {
  content: '\ea1d';
}

.icofont-turkish-lira:before {
  content: '\ea1e';
}

.icofont-won-false:before {
  content: '\ea1f';
}

.icofont-won-minus:before {
  content: '\ea20';
}

.icofont-won-plus:before {
  content: '\ea21';
}

.icofont-won-true:before {
  content: '\ea22';
}

.icofont-won:before {
  content: '\ea23';
}

.icofont-yen-false:before {
  content: '\ea24';
}

.icofont-yen-minus:before {
  content: '\ea25';
}

.icofont-yen-plus:before {
  content: '\ea26';
}

.icofont-yen-true:before {
  content: '\ea27';
}

.icofont-yen:before {
  content: '\ea28';
}

.icofont-android-nexus:before {
  content: '\ea29';
}

.icofont-android-tablet:before {
  content: '\ea2a';
}

.icofont-apple-watch:before {
  content: '\ea2b';
}

.icofont-drawing-tablet:before {
  content: '\ea2c';
}

.icofont-earphone:before {
  content: '\ea2d';
}

.icofont-flash-drive:before {
  content: '\ea2e';
}

.icofont-game-console:before {
  content: '\ea2f';
}

.icofont-game-controller:before {
  content: '\ea30';
}

.icofont-game-pad:before {
  content: '\ea31';
}

.icofont-game:before {
  content: '\ea32';
}

.icofont-headphone-alt-1:before {
  content: '\ea33';
}

.icofont-headphone-alt-2:before {
  content: '\ea34';
}

.icofont-headphone-alt-3:before {
  content: '\ea35';
}

.icofont-headphone-alt:before {
  content: '\ea36';
}

.icofont-headphone:before {
  content: '\ea37';
}

.icofont-htc-one:before {
  content: '\ea38';
}

.icofont-imac:before {
  content: '\ea39';
}

.icofont-ipad:before {
  content: '\ea3a';
}

.icofont-iphone:before {
  content: '\ea3b';
}

.icofont-ipod-nano:before {
  content: '\ea3c';
}

.icofont-ipod-touch:before {
  content: '\ea3d';
}

.icofont-keyboard-alt:before {
  content: '\ea3e';
}

.icofont-keyboard-wireless:before {
  content: '\ea3f';
}

.icofont-keyboard:before {
  content: '\ea40';
}

.icofont-laptop-alt:before {
  content: '\ea41';
}

.icofont-laptop:before {
  content: '\ea42';
}

.icofont-macbook:before {
  content: '\ea43';
}

.icofont-magic-mouse:before {
  content: '\ea44';
}

.icofont-micro-chip:before {
  content: '\ea45';
}

.icofont-microphone-alt:before {
  content: '\ea46';
}

.icofont-microphone:before {
  content: '\ea47';
}

.icofont-monitor:before {
  content: '\ea48';
}

.icofont-mouse:before {
  content: '\ea49';
}

.icofont-mp3-player:before {
  content: '\ea4a';
}

.icofont-nintendo:before {
  content: '\ea4b';
}

.icofont-playstation-alt:before {
  content: '\ea4c';
}

.icofont-psvita:before {
  content: '\ea4d';
}

.icofont-radio-mic:before {
  content: '\ea4e';
}

.icofont-radio:before {
  content: '\ea4f';
}

.icofont-refrigerator:before {
  content: '\ea50';
}

.icofont-samsung-galaxy:before {
  content: '\ea51';
}

.icofont-surface-tablet:before {
  content: '\ea52';
}

.icofont-ui-head-phone:before {
  content: '\ea53';
}

.icofont-ui-keyboard:before {
  content: '\ea54';
}

.icofont-washing-machine:before {
  content: '\ea55';
}

.icofont-wifi-router:before {
  content: '\ea56';
}

.icofont-wii-u:before {
  content: '\ea57';
}

.icofont-windows-lumia:before {
  content: '\ea58';
}

.icofont-wireless-mouse:before {
  content: '\ea59';
}

.icofont-xbox-360:before {
  content: '\ea5a';
}

.icofont-arrow-down:before {
  content: '\ea5b';
}

.icofont-arrow-left:before {
  content: '\ea5c';
}

.icofont-arrow-right:before {
  content: '\ea5d';
}

.icofont-arrow-up:before {
  content: '\ea5e';
}

.icofont-block-down:before {
  content: '\ea5f';
}

.icofont-block-left:before {
  content: '\ea60';
}

.icofont-block-right:before {
  content: '\ea61';
}

.icofont-block-up:before {
  content: '\ea62';
}

.icofont-bubble-down:before {
  content: '\ea63';
}

.icofont-bubble-left:before {
  content: '\ea64';
}

.icofont-bubble-right:before {
  content: '\ea65';
}

.icofont-bubble-up:before {
  content: '\ea66';
}

.icofont-caret-down:before {
  content: '\ea67';
}

.icofont-caret-left:before {
  content: '\ea68';
}

.icofont-caret-right:before {
  content: '\ea69';
}

.icofont-caret-up:before {
  content: '\ea6a';
}

.icofont-circled-down:before {
  content: '\ea6b';
}

.icofont-circled-left:before {
  content: '\ea6c';
}

.icofont-circled-right:before {
  content: '\ea6d';
}

.icofont-circled-up:before {
  content: '\ea6e';
}

.icofont-collapse:before {
  content: '\ea6f';
}

.icofont-cursor-drag:before {
  content: '\ea70';
}

.icofont-curved-double-left:before {
  content: '\ea71';
}

.icofont-curved-double-right:before {
  content: '\ea72';
}

.icofont-curved-down:before {
  content: '\ea73';
}

.icofont-curved-left:before {
  content: '\ea74';
}

.icofont-curved-right:before {
  content: '\ea75';
}

.icofont-curved-up:before {
  content: '\ea76';
}

.icofont-dotted-down:before {
  content: '\ea77';
}

.icofont-dotted-left:before {
  content: '\ea78';
}

.icofont-dotted-right:before {
  content: '\ea79';
}

.icofont-dotted-up:before {
  content: '\ea7a';
}

.icofont-double-left:before {
  content: '\ea7b';
}

.icofont-double-right:before {
  content: '\ea7c';
}

.icofont-expand-alt:before {
  content: '\ea7d';
}

.icofont-hand-down:before {
  content: '\ea7e';
}

.icofont-hand-drag:before {
  content: '\ea7f';
}

.icofont-hand-drag1:before {
  content: '\ea80';
}

.icofont-hand-drag2:before {
  content: '\ea81';
}

.icofont-hand-drawn-alt-down:before {
  content: '\ea82';
}

.icofont-hand-drawn-alt-left:before {
  content: '\ea83';
}

.icofont-hand-drawn-alt-right:before {
  content: '\ea84';
}

.icofont-hand-drawn-alt-up:before {
  content: '\ea85';
}

.icofont-hand-drawn-down:before {
  content: '\ea86';
}

.icofont-hand-drawn-left:before {
  content: '\ea87';
}

.icofont-hand-drawn-right:before {
  content: '\ea88';
}

.icofont-hand-drawn-up:before {
  content: '\ea89';
}

.icofont-hand-grippers:before {
  content: '\ea8a';
}

.icofont-hand-left:before {
  content: '\ea8b';
}

.icofont-hand-right:before {
  content: '\ea8c';
}

.icofont-hand-up:before {
  content: '\ea8d';
}

.icofont-line-block-down:before {
  content: '\ea8e';
}

.icofont-line-block-left:before {
  content: '\ea8f';
}

.icofont-line-block-right:before {
  content: '\ea90';
}

.icofont-line-block-up:before {
  content: '\ea91';
}

.icofont-long-arrow-down:before {
  content: '\ea92';
}

.icofont-long-arrow-left:before {
  content: '\ea93';
}

.icofont-long-arrow-right:before {
  content: '\ea94';
}

.icofont-long-arrow-up:before {
  content: '\ea95';
}

.icofont-rounded-collapse:before {
  content: '\ea96';
}

.icofont-rounded-double-left:before {
  content: '\ea97';
}

.icofont-rounded-double-right:before {
  content: '\ea98';
}

.icofont-rounded-down:before {
  content: '\ea99';
}

.icofont-rounded-expand:before {
  content: '\ea9a';
}

.icofont-rounded-left-down:before {
  content: '\ea9b';
}

.icofont-rounded-left-up:before {
  content: '\ea9c';
}

.icofont-rounded-left:before {
  content: '\ea9d';
}

.icofont-rounded-right-down:before {
  content: '\ea9e';
}

.icofont-rounded-right-up:before {
  content: '\ea9f';
}

.icofont-rounded-right:before {
  content: '\eaa0';
}

.icofont-rounded-up:before {
  content: '\eaa1';
}

.icofont-scroll-bubble-down:before {
  content: '\eaa2';
}

.icofont-scroll-bubble-left:before {
  content: '\eaa3';
}

.icofont-scroll-bubble-right:before {
  content: '\eaa4';
}

.icofont-scroll-bubble-up:before {
  content: '\eaa5';
}

.icofont-scroll-double-down:before {
  content: '\eaa6';
}

.icofont-scroll-double-left:before {
  content: '\eaa7';
}

.icofont-scroll-double-right:before {
  content: '\eaa8';
}

.icofont-scroll-double-up:before {
  content: '\eaa9';
}

.icofont-scroll-down:before {
  content: '\eaaa';
}

.icofont-scroll-left:before {
  content: '\eaab';
}

.icofont-scroll-long-down:before {
  content: '\eaac';
}

.icofont-scroll-long-left:before {
  content: '\eaad';
}

.icofont-scroll-long-right:before {
  content: '\eaae';
}

.icofont-scroll-long-up:before {
  content: '\eaaf';
}

.icofont-scroll-right:before {
  content: '\eab0';
}

.icofont-scroll-up:before {
  content: '\eab1';
}

.icofont-simple-down:before {
  content: '\eab2';
}

.icofont-simple-left-down:before {
  content: '\eab3';
}

.icofont-simple-left-up:before {
  content: '\eab4';
}

.icofont-simple-left:before {
  content: '\eab5';
}

.icofont-simple-right-down:before {
  content: '\eab6';
}

.icofont-simple-right-up:before {
  content: '\eab7';
}

.icofont-simple-right:before {
  content: '\eab8';
}

.icofont-simple-up:before {
  content: '\eab9';
}

.icofont-square-down:before {
  content: '\eaba';
}

.icofont-square-left:before {
  content: '\eabb';
}

.icofont-square-right:before {
  content: '\eabc';
}

.icofont-square-up:before {
  content: '\eabd';
}

.icofont-stylish-down:before {
  content: '\eabe';
}

.icofont-stylish-left:before {
  content: '\eabf';
}

.icofont-stylish-right:before {
  content: '\eac0';
}

.icofont-stylish-up:before {
  content: '\eac1';
}

.icofont-swoosh-down:before {
  content: '\eac2';
}

.icofont-swoosh-left:before {
  content: '\eac3';
}

.icofont-swoosh-right:before {
  content: '\eac4';
}

.icofont-swoosh-up:before {
  content: '\eac5';
}

.icofont-thin-double-left:before {
  content: '\eac6';
}

.icofont-thin-double-right:before {
  content: '\eac7';
}

.icofont-thin-down:before {
  content: '\eac8';
}

.icofont-thin-left:before {
  content: '\eac9';
}

.icofont-thin-right:before {
  content: '\eaca';
}

.icofont-thin-up:before {
  content: '\eacb';
}

.icofont-abc:before {
  content: '\eacc';
}

.icofont-atom:before {
  content: '\eacd';
}

.icofont-award:before {
  content: '\eace';
}

.icofont-bell-alt:before {
  content: '\eacf';
}

.icofont-black-board:before {
  content: '\ead0';
}

.icofont-book-alt:before {
  content: '\ead1';
}

.icofont-book:before {
  content: '\ead2';
}

.icofont-brainstorming:before {
  content: '\ead3';
}

.icofont-certificate-alt-1:before {
  content: '\ead4';
}

.icofont-certificate-alt-2:before {
  content: '\ead5';
}

.icofont-certificate:before {
  content: '\ead6';
}

.icofont-education:before {
  content: '\ead7';
}

.icofont-electron:before {
  content: '\ead8';
}

.icofont-fountain-pen:before {
  content: '\ead9';
}

.icofont-globe-alt:before {
  content: '\eada';
}

.icofont-graduate-alt:before {
  content: '\eadb';
}

.icofont-graduate:before {
  content: '\eadc';
}

.icofont-group-students:before {
  content: '\eadd';
}

.icofont-hat-alt:before {
  content: '\eade';
}

.icofont-hat:before {
  content: '\eadf';
}

.icofont-instrument:before {
  content: '\eae0';
}

.icofont-lamp-light:before {
  content: '\eae1';
}

.icofont-medal:before {
  content: '\eae2';
}

.icofont-microscope-alt:before {
  content: '\eae3';
}

.icofont-microscope:before {
  content: '\eae4';
}

.icofont-paper:before {
  content: '\eae5';
}

.icofont-pen-alt-4:before {
  content: '\eae6';
}

.icofont-pen-nib:before {
  content: '\eae7';
}

.icofont-pencil-alt-5:before {
  content: '\eae8';
}

.icofont-quill-pen:before {
  content: '\eae9';
}

.icofont-read-book-alt:before {
  content: '\eaea';
}

.icofont-read-book:before {
  content: '\eaeb';
}

.icofont-school-bag:before {
  content: '\eaec';
}

.icofont-school-bus:before {
  content: '\eaed';
}

.icofont-student-alt:before {
  content: '\eaee';
}

.icofont-student:before {
  content: '\eaef';
}

.icofont-teacher:before {
  content: '\eaf0';
}

.icofont-test-bulb:before {
  content: '\eaf1';
}

.icofont-test-tube-alt:before {
  content: '\eaf2';
}

.icofont-university:before {
  content: '\eaf3';
}

.icofont-angry:before {
  content: '\eaf4';
}

.icofont-astonished:before {
  content: '\eaf5';
}

.icofont-confounded:before {
  content: '\eaf6';
}

.icofont-confused:before {
  content: '\eaf7';
}

.icofont-crying:before {
  content: '\eaf8';
}

.icofont-dizzy:before {
  content: '\eaf9';
}

.icofont-expressionless:before {
  content: '\eafa';
}

.icofont-heart-eyes:before {
  content: '\eafb';
}

.icofont-laughing:before {
  content: '\eafc';
}

.icofont-nerd-smile:before {
  content: '\eafd';
}

.icofont-open-mouth:before {
  content: '\eafe';
}

.icofont-rage:before {
  content: '\eaff';
}

.icofont-rolling-eyes:before {
  content: '\eb00';
}

.icofont-sad:before {
  content: '\eb01';
}

.icofont-simple-smile:before {
  content: '\eb02';
}

.icofont-slightly-smile:before {
  content: '\eb03';
}

.icofont-smirk:before {
  content: '\eb04';
}

.icofont-stuck-out-tongue:before {
  content: '\eb05';
}

.icofont-wink-smile:before {
  content: '\eb06';
}

.icofont-worried:before {
  content: '\eb07';
}

.icofont-file-alt:before {
  content: '\eb08';
}

.icofont-file-audio:before {
  content: '\eb09';
}

.icofont-file-avi-mp4:before {
  content: '\eb0a';
}

.icofont-file-bmp:before {
  content: '\eb0b';
}

.icofont-file-code:before {
  content: '\eb0c';
}

.icofont-file-css:before {
  content: '\eb0d';
}

.icofont-file-document:before {
  content: '\eb0e';
}

.icofont-file-eps:before {
  content: '\eb0f';
}

.icofont-file-excel:before {
  content: '\eb10';
}

.icofont-file-exe:before {
  content: '\eb11';
}

.icofont-file-file:before {
  content: '\eb12';
}

.icofont-file-flv:before {
  content: '\eb13';
}

.icofont-file-gif:before {
  content: '\eb14';
}

.icofont-file-html5:before {
  content: '\eb15';
}

.icofont-file-image:before {
  content: '\eb16';
}

.icofont-file-iso:before {
  content: '\eb17';
}

.icofont-file-java:before {
  content: '\eb18';
}

.icofont-file-javascript:before {
  content: '\eb19';
}

.icofont-file-jpg:before {
  content: '\eb1a';
}

.icofont-file-midi:before {
  content: '\eb1b';
}

.icofont-file-mov:before {
  content: '\eb1c';
}

.icofont-file-mp3:before {
  content: '\eb1d';
}

.icofont-file-pdf:before {
  content: '\eb1e';
}

.icofont-file-php:before {
  content: '\eb1f';
}

.icofont-file-png:before {
  content: '\eb20';
}

.icofont-file-powerpoint:before {
  content: '\eb21';
}

.icofont-file-presentation:before {
  content: '\eb22';
}

.icofont-file-psb:before {
  content: '\eb23';
}

.icofont-file-psd:before {
  content: '\eb24';
}

.icofont-file-python:before {
  content: '\eb25';
}

.icofont-file-ruby:before {
  content: '\eb26';
}

.icofont-file-spreadsheet:before {
  content: '\eb27';
}

.icofont-file-sql:before {
  content: '\eb28';
}

.icofont-file-svg:before {
  content: '\eb29';
}

.icofont-file-text:before {
  content: '\eb2a';
}

.icofont-file-tiff:before {
  content: '\eb2b';
}

.icofont-file-video:before {
  content: '\eb2c';
}

.icofont-file-wave:before {
  content: '\eb2d';
}

.icofont-file-wmv:before {
  content: '\eb2e';
}

.icofont-file-word:before {
  content: '\eb2f';
}

.icofont-file-zip:before {
  content: '\eb30';
}

.icofont-cycling-alt:before {
  content: '\eb31';
}

.icofont-cycling:before {
  content: '\eb32';
}

.icofont-dumbbell:before {
  content: '\eb33';
}

.icofont-dumbbells:before {
  content: '\eb34';
}

.icofont-gym-alt-1:before {
  content: '\eb35';
}

.icofont-gym-alt-2:before {
  content: '\eb36';
}

.icofont-gym-alt-3:before {
  content: '\eb37';
}

.icofont-gym:before {
  content: '\eb38';
}

.icofont-muscle-weight:before {
  content: '\eb39';
}

.icofont-muscle:before {
  content: '\eb3a';
}

.icofont-apple:before {
  content: '\eb3b';
}

.icofont-arabian-coffee:before {
  content: '\eb3c';
}

.icofont-artichoke:before {
  content: '\eb3d';
}

.icofont-asparagus:before {
  content: '\eb3e';
}

.icofont-avocado:before {
  content: '\eb3f';
}

.icofont-baby-food:before {
  content: '\eb40';
}

.icofont-banana:before {
  content: '\eb41';
}

.icofont-bbq:before {
  content: '\eb42';
}

.icofont-beans:before {
  content: '\eb43';
}

.icofont-beer:before {
  content: '\eb44';
}

.icofont-bell-pepper-capsicum:before {
  content: '\eb45';
}

.icofont-birthday-cake:before {
  content: '\eb46';
}

.icofont-bread:before {
  content: '\eb47';
}

.icofont-broccoli:before {
  content: '\eb48';
}

.icofont-burger:before {
  content: '\eb49';
}

.icofont-cabbage:before {
  content: '\eb4a';
}

.icofont-carrot:before {
  content: '\eb4b';
}

.icofont-cauli-flower:before {
  content: '\eb4c';
}

.icofont-cheese:before {
  content: '\eb4d';
}

.icofont-chef:before {
  content: '\eb4e';
}

.icofont-cherry:before {
  content: '\eb4f';
}

.icofont-chicken-fry:before {
  content: '\eb50';
}

.icofont-chicken:before {
  content: '\eb51';
}

.icofont-cocktail:before {
  content: '\eb52';
}

.icofont-coconut-water:before {
  content: '\eb53';
}

.icofont-coconut:before {
  content: '\eb54';
}

.icofont-coffee-alt:before {
  content: '\eb55';
}

.icofont-coffee-cup:before {
  content: '\eb56';
}

.icofont-coffee-mug:before {
  content: '\eb57';
}

.icofont-coffee-pot:before {
  content: '\eb58';
}

.icofont-cola:before {
  content: '\eb59';
}

.icofont-corn:before {
  content: '\eb5a';
}

.icofont-croissant:before {
  content: '\eb5b';
}

.icofont-crop-plant:before {
  content: '\eb5c';
}

.icofont-cucumber:before {
  content: '\eb5d';
}

.icofont-culinary:before {
  content: '\eb5e';
}

.icofont-cup-cake:before {
  content: '\eb5f';
}

.icofont-dining-table:before {
  content: '\eb60';
}

.icofont-donut:before {
  content: '\eb61';
}

.icofont-egg-plant:before {
  content: '\eb62';
}

.icofont-egg-poached:before {
  content: '\eb63';
}

.icofont-farmer-alt:before {
  content: '\eb64';
}

.icofont-farmer:before {
  content: '\eb65';
}

.icofont-fast-food:before {
  content: '\eb66';
}

.icofont-food-basket:before {
  content: '\eb67';
}

.icofont-food-cart:before {
  content: '\eb68';
}

.icofont-fork-and-knife:before {
  content: '\eb69';
}

.icofont-french-fries:before {
  content: '\eb6a';
}

.icofont-fruits:before {
  content: '\eb6b';
}

.icofont-grapes:before {
  content: '\eb6c';
}

.icofont-honey:before {
  content: '\eb6d';
}

.icofont-hot-dog:before {
  content: '\eb6e';
}

.icofont-ice-cream-alt:before {
  content: '\eb6f';
}

.icofont-ice-cream:before {
  content: '\eb70';
}

.icofont-juice:before {
  content: '\eb71';
}

.icofont-ketchup:before {
  content: '\eb72';
}

.icofont-kiwi:before {
  content: '\eb73';
}

.icofont-layered-cake:before {
  content: '\eb74';
}

.icofont-lemon-alt:before {
  content: '\eb75';
}

.icofont-lemon:before {
  content: '\eb76';
}

.icofont-lobster:before {
  content: '\eb77';
}

.icofont-mango:before {
  content: '\eb78';
}

.icofont-milk:before {
  content: '\eb79';
}

.icofont-mushroom:before {
  content: '\eb7a';
}

.icofont-noodles:before {
  content: '\eb7b';
}

.icofont-onion:before {
  content: '\eb7c';
}

.icofont-orange:before {
  content: '\eb7d';
}

.icofont-pear:before {
  content: '\eb7e';
}

.icofont-peas:before {
  content: '\eb7f';
}

.icofont-pepper:before {
  content: '\eb80';
}

.icofont-pie-alt:before {
  content: '\eb81';
}

.icofont-pie:before {
  content: '\eb82';
}

.icofont-pineapple:before {
  content: '\eb83';
}

.icofont-pizza-slice:before {
  content: '\eb84';
}

.icofont-pizza:before {
  content: '\eb85';
}

.icofont-plant:before {
  content: '\eb86';
}

.icofont-popcorn:before {
  content: '\eb87';
}

.icofont-potato:before {
  content: '\eb88';
}

.icofont-pumpkin:before {
  content: '\eb89';
}

.icofont-raddish:before {
  content: '\eb8a';
}

.icofont-restaurant-menu:before {
  content: '\eb8b';
}

.icofont-restaurant:before {
  content: '\eb8c';
}

.icofont-salt-and-pepper:before {
  content: '\eb8d';
}

.icofont-sandwich:before {
  content: '\eb8e';
}

.icofont-sausage:before {
  content: '\eb8f';
}

.icofont-soft-drinks:before {
  content: '\eb90';
}

.icofont-soup-bowl:before {
  content: '\eb91';
}

.icofont-spoon-and-fork:before {
  content: '\eb92';
}

.icofont-steak:before {
  content: '\eb93';
}

.icofont-strawberry:before {
  content: '\eb94';
}

.icofont-sub-sandwich:before {
  content: '\eb95';
}

.icofont-sushi:before {
  content: '\eb96';
}

.icofont-taco:before {
  content: '\eb97';
}

.icofont-tea-pot:before {
  content: '\eb98';
}

.icofont-tea:before {
  content: '\eb99';
}

.icofont-tomato:before {
  content: '\eb9a';
}

.icofont-watermelon:before {
  content: '\eb9b';
}

.icofont-wheat:before {
  content: '\eb9c';
}

.icofont-baby-backpack:before {
  content: '\eb9d';
}

.icofont-baby-cloth:before {
  content: '\eb9e';
}

.icofont-baby-milk-bottle:before {
  content: '\eb9f';
}

.icofont-baby-trolley:before {
  content: '\eba0';
}

.icofont-baby:before {
  content: '\eba1';
}

.icofont-candy:before {
  content: '\eba2';
}

.icofont-holding-hands:before {
  content: '\eba3';
}

.icofont-infant-nipple:before {
  content: '\eba4';
}

.icofont-kids-scooter:before {
  content: '\eba5';
}

.icofont-safety-pin:before {
  content: '\eba6';
}

.icofont-teddy-bear:before {
  content: '\eba7';
}

.icofont-toy-ball:before {
  content: '\eba8';
}

.icofont-toy-cat:before {
  content: '\eba9';
}

.icofont-toy-duck:before {
  content: '\ebaa';
}

.icofont-toy-elephant:before {
  content: '\ebab';
}

.icofont-toy-hand:before {
  content: '\ebac';
}

.icofont-toy-horse:before {
  content: '\ebad';
}

.icofont-toy-lattu:before {
  content: '\ebae';
}

.icofont-toy-train:before {
  content: '\ebaf';
}

.icofont-burglar:before {
  content: '\ebb0';
}

.icofont-cannon-firing:before {
  content: '\ebb1';
}

.icofont-cc-camera:before {
  content: '\ebb2';
}

.icofont-cop-badge:before {
  content: '\ebb3';
}

.icofont-cop:before {
  content: '\ebb4';
}

.icofont-court-hammer:before {
  content: '\ebb5';
}

.icofont-court:before {
  content: '\ebb6';
}

.icofont-finger-print:before {
  content: '\ebb7';
}

.icofont-gavel:before {
  content: '\ebb8';
}

.icofont-handcuff-alt:before {
  content: '\ebb9';
}

.icofont-handcuff:before {
  content: '\ebba';
}

.icofont-investigation:before {
  content: '\ebbb';
}

.icofont-investigator:before {
  content: '\ebbc';
}

.icofont-jail:before {
  content: '\ebbd';
}

.icofont-judge:before {
  content: '\ebbe';
}

.icofont-law-alt-1:before {
  content: '\ebbf';
}

.icofont-law-alt-2:before {
  content: '\ebc0';
}

.icofont-law-alt-3:before {
  content: '\ebc1';
}

.icofont-law-book:before {
  content: '\ebc2';
}

.icofont-law-document:before {
  content: '\ebc3';
}

.icofont-law-order:before {
  content: '\ebc4';
}

.icofont-law-protect:before {
  content: '\ebc5';
}

.icofont-law-scales:before {
  content: '\ebc6';
}

.icofont-law:before {
  content: '\ebc7';
}

.icofont-lawyer-alt-1:before {
  content: '\ebc8';
}

.icofont-lawyer-alt-2:before {
  content: '\ebc9';
}

.icofont-lawyer:before {
  content: '\ebca';
}

.icofont-legal:before {
  content: '\ebcb';
}

.icofont-pistol:before {
  content: '\ebcc';
}

.icofont-police-badge:before {
  content: '\ebcd';
}

.icofont-police-cap:before {
  content: '\ebce';
}

.icofont-police-car-alt-1:before {
  content: '\ebcf';
}

.icofont-police-car-alt-2:before {
  content: '\ebd0';
}

.icofont-police-car:before {
  content: '\ebd1';
}

.icofont-police-hat:before {
  content: '\ebd2';
}

.icofont-police-van:before {
  content: '\ebd3';
}

.icofont-police:before {
  content: '\ebd4';
}

.icofont-thief-alt:before {
  content: '\ebd5';
}

.icofont-thief:before {
  content: '\ebd6';
}

.icofont-abacus-alt:before {
  content: '\ebd7';
}

.icofont-abacus:before {
  content: '\ebd8';
}

.icofont-angle-180:before {
  content: '\ebd9';
}

.icofont-angle-45:before {
  content: '\ebda';
}

.icofont-angle-90:before {
  content: '\ebdb';
}

.icofont-angle:before {
  content: '\ebdc';
}

.icofont-calculator-alt-1:before {
  content: '\ebdd';
}

.icofont-calculator-alt-2:before {
  content: '\ebde';
}

.icofont-calculator:before {
  content: '\ebdf';
}

.icofont-circle-ruler-alt:before {
  content: '\ebe0';
}

.icofont-circle-ruler:before {
  content: '\ebe1';
}

.icofont-compass-alt-1:before {
  content: '\ebe2';
}

.icofont-compass-alt-2:before {
  content: '\ebe3';
}

.icofont-compass-alt-3:before {
  content: '\ebe4';
}

.icofont-compass-alt-4:before {
  content: '\ebe5';
}

.icofont-golden-ratio:before {
  content: '\ebe6';
}

.icofont-marker-alt-1:before {
  content: '\ebe7';
}

.icofont-marker-alt-2:before {
  content: '\ebe8';
}

.icofont-marker-alt-3:before {
  content: '\ebe9';
}

.icofont-marker:before {
  content: '\ebea';
}

.icofont-math:before {
  content: '\ebeb';
}

.icofont-mathematical-alt-1:before {
  content: '\ebec';
}

.icofont-mathematical-alt-2:before {
  content: '\ebed';
}

.icofont-mathematical:before {
  content: '\ebee';
}

.icofont-pen-alt-1:before {
  content: '\ebef';
}

.icofont-pen-alt-2:before {
  content: '\ebf0';
}

.icofont-pen-alt-3:before {
  content: '\ebf1';
}

.icofont-pen-holder-alt-1:before {
  content: '\ebf2';
}

.icofont-pen-holder:before {
  content: '\ebf3';
}

.icofont-pen:before {
  content: '\ebf4';
}

.icofont-pencil-alt-1:before {
  content: '\ebf5';
}

.icofont-pencil-alt-2:before {
  content: '\ebf6';
}

.icofont-pencil-alt-3:before {
  content: '\ebf7';
}

.icofont-pencil-alt-4:before {
  content: '\ebf8';
}

.icofont-pencil:before {
  content: '\ebf9';
}

.icofont-ruler-alt-1:before {
  content: '\ebfa';
}

.icofont-ruler-alt-2:before {
  content: '\ebfb';
}

.icofont-ruler-compass-alt:before {
  content: '\ebfc';
}

.icofont-ruler-compass:before {
  content: '\ebfd';
}

.icofont-ruler-pencil-alt-1:before {
  content: '\ebfe';
}

.icofont-ruler-pencil-alt-2:before {
  content: '\ebff';
}

.icofont-ruler-pencil:before {
  content: '\ec00';
}

.icofont-ruler:before {
  content: '\ec01';
}

.icofont-rulers-alt:before {
  content: '\ec02';
}

.icofont-rulers:before {
  content: '\ec03';
}

.icofont-square-root:before {
  content: '\ec04';
}

.icofont-ui-calculator:before {
  content: '\ec05';
}

.icofont-aids:before {
  content: '\ec06';
}

.icofont-ambulance-crescent:before {
  content: '\ec07';
}

.icofont-ambulance-cross:before {
  content: '\ec08';
}

.icofont-ambulance:before {
  content: '\ec09';
}

.icofont-autism:before {
  content: '\ec0a';
}

.icofont-bandage:before {
  content: '\ec0b';
}

.icofont-blind:before {
  content: '\ec0c';
}

.icofont-blood-drop:before {
  content: '\ec0d';
}

.icofont-blood-test:before {
  content: '\ec0e';
}

.icofont-blood:before {
  content: '\ec0f';
}

.icofont-brain-alt:before {
  content: '\ec10';
}

.icofont-brain:before {
  content: '\ec11';
}

.icofont-capsule:before {
  content: '\ec12';
}

.icofont-crutch:before {
  content: '\ec13';
}

.icofont-disabled:before {
  content: '\ec14';
}

.icofont-dna-alt-1:before {
  content: '\ec15';
}

.icofont-dna-alt-2:before {
  content: '\ec16';
}

.icofont-dna:before {
  content: '\ec17';
}

.icofont-doctor-alt:before {
  content: '\ec18';
}

.icofont-doctor:before {
  content: '\ec19';
}

.icofont-drug-pack:before {
  content: '\ec1a';
}

.icofont-drug:before {
  content: '\ec1b';
}

.icofont-first-aid-alt:before {
  content: '\ec1c';
}

.icofont-first-aid:before {
  content: '\ec1d';
}

.icofont-heart-beat-alt:before {
  content: '\ec1e';
}

.icofont-heart-beat:before {
  content: '\ec1f';
}

.icofont-heartbeat:before {
  content: '\ec20';
}

.icofont-herbal:before {
  content: '\ec21';
}

.icofont-hospital:before {
  content: '\ec22';
}

.icofont-icu:before {
  content: '\ec23';
}

.icofont-injection-syringe:before {
  content: '\ec24';
}

.icofont-laboratory:before {
  content: '\ec25';
}

.icofont-medical-sign-alt:before {
  content: '\ec26';
}

.icofont-medical-sign:before {
  content: '\ec27';
}

.icofont-nurse-alt:before {
  content: '\ec28';
}

.icofont-nurse:before {
  content: '\ec29';
}

.icofont-nursing-home:before {
  content: '\ec2a';
}

.icofont-operation-theater:before {
  content: '\ec2b';
}

.icofont-paralysis-disability:before {
  content: '\ec2c';
}

.icofont-patient-bed:before {
  content: '\ec2d';
}

.icofont-patient-file:before {
  content: '\ec2e';
}

.icofont-pills:before {
  content: '\ec2f';
}

.icofont-prescription:before {
  content: '\ec30';
}

.icofont-pulse:before {
  content: '\ec31';
}

.icofont-stethoscope-alt:before {
  content: '\ec32';
}

.icofont-stethoscope:before {
  content: '\ec33';
}

.icofont-stretcher:before {
  content: '\ec34';
}

.icofont-surgeon-alt:before {
  content: '\ec35';
}

.icofont-surgeon:before {
  content: '\ec36';
}

.icofont-tablets:before {
  content: '\ec37';
}

.icofont-test-bottle:before {
  content: '\ec38';
}

.icofont-test-tube:before {
  content: '\ec39';
}

.icofont-thermometer-alt:before {
  content: '\ec3a';
}

.icofont-thermometer:before {
  content: '\ec3b';
}

.icofont-tooth:before {
  content: '\ec3c';
}

.icofont-xray:before {
  content: '\ec3d';
}

.icofont-ui-add:before {
  content: '\ec3e';
}

.icofont-ui-alarm:before {
  content: '\ec3f';
}

.icofont-ui-battery:before {
  content: '\ec40';
}

.icofont-ui-block:before {
  content: '\ec41';
}

.icofont-ui-bluetooth:before {
  content: '\ec42';
}

.icofont-ui-brightness:before {
  content: '\ec43';
}

.icofont-ui-browser:before {
  content: '\ec44';
}

.icofont-ui-calendar:before {
  content: '\ec45';
}

.icofont-ui-call:before {
  content: '\ec46';
}

.icofont-ui-camera:before {
  content: '\ec47';
}

.icofont-ui-cart:before {
  content: '\ec48';
}

.icofont-ui-cell-phone:before {
  content: '\ec49';
}

.icofont-ui-chat:before {
  content: '\ec4a';
}

.icofont-ui-check:before {
  content: '\ec4b';
}

.icofont-ui-clip-board:before {
  content: '\ec4c';
}

.icofont-ui-clip:before {
  content: '\ec4d';
}

.icofont-ui-clock:before {
  content: '\ec4e';
}

.icofont-ui-close:before {
  content: '\ec4f';
}

.icofont-ui-contact-list:before {
  content: '\ec50';
}

.icofont-ui-copy:before {
  content: '\ec51';
}

.icofont-ui-cut:before {
  content: '\ec52';
}

.icofont-ui-delete:before {
  content: '\ec53';
}

.icofont-ui-dial-phone:before {
  content: '\ec54';
}

.icofont-ui-edit:before {
  content: '\ec55';
}

.icofont-ui-email:before {
  content: '\ec56';
}

.icofont-ui-file:before {
  content: '\ec57';
}

.icofont-ui-fire-wall:before {
  content: '\ec58';
}

.icofont-ui-flash-light:before {
  content: '\ec59';
}

.icofont-ui-flight:before {
  content: '\ec5a';
}

.icofont-ui-folder:before {
  content: '\ec5b';
}

.icofont-ui-game:before {
  content: '\ec5c';
}

.icofont-ui-handicapped:before {
  content: '\ec5d';
}

.icofont-ui-home:before {
  content: '\ec5e';
}

.icofont-ui-image:before {
  content: '\ec5f';
}

.icofont-ui-laoding:before {
  content: '\ec60';
}

.icofont-ui-lock:before {
  content: '\ec61';
}

.icofont-ui-love-add:before {
  content: '\ec62';
}

.icofont-ui-love-broken:before {
  content: '\ec63';
}

.icofont-ui-love-remove:before {
  content: '\ec64';
}

.icofont-ui-love:before {
  content: '\ec65';
}

.icofont-ui-map:before {
  content: '\ec66';
}

.icofont-ui-message:before {
  content: '\ec67';
}

.icofont-ui-messaging:before {
  content: '\ec68';
}

.icofont-ui-movie:before {
  content: '\ec69';
}

.icofont-ui-music-player:before {
  content: '\ec6a';
}

.icofont-ui-music:before {
  content: '\ec6b';
}

.icofont-ui-mute:before {
  content: '\ec6c';
}

.icofont-ui-network:before {
  content: '\ec6d';
}

.icofont-ui-next:before {
  content: '\ec6e';
}

.icofont-ui-note:before {
  content: '\ec6f';
}

.icofont-ui-office:before {
  content: '\ec70';
}

.icofont-ui-password:before {
  content: '\ec71';
}

.icofont-ui-pause:before {
  content: '\ec72';
}

.icofont-ui-play-stop:before {
  content: '\ec73';
}

.icofont-ui-play:before {
  content: '\ec74';
}

.icofont-ui-pointer:before {
  content: '\ec75';
}

.icofont-ui-power:before {
  content: '\ec76';
}

.icofont-ui-press:before {
  content: '\ec77';
}

.icofont-ui-previous:before {
  content: '\ec78';
}

.icofont-ui-rate-add:before {
  content: '\ec79';
}

.icofont-ui-rate-blank:before {
  content: '\ec7a';
}

.icofont-ui-rate-remove:before {
  content: '\ec7b';
}

.icofont-ui-rating:before {
  content: '\ec7c';
}

.icofont-ui-record:before {
  content: '\ec7d';
}

.icofont-ui-remove:before {
  content: '\ec7e';
}

.icofont-ui-reply:before {
  content: '\ec7f';
}

.icofont-ui-rotation:before {
  content: '\ec80';
}

.icofont-ui-rss:before {
  content: '\ec81';
}

.icofont-ui-search:before {
  content: '\ec82';
}

.icofont-ui-settings:before {
  content: '\ec83';
}

.icofont-ui-social-link:before {
  content: '\ec84';
}

.icofont-ui-tag:before {
  content: '\ec85';
}

.icofont-ui-text-chat:before {
  content: '\ec86';
}

.icofont-ui-text-loading:before {
  content: '\ec87';
}

.icofont-ui-theme:before {
  content: '\ec88';
}

.icofont-ui-timer:before {
  content: '\ec89';
}

.icofont-ui-touch-phone:before {
  content: '\ec8a';
}

.icofont-ui-travel:before {
  content: '\ec8b';
}

.icofont-ui-unlock:before {
  content: '\ec8c';
}

.icofont-ui-user-group:before {
  content: '\ec8d';
}

.icofont-ui-user:before {
  content: '\ec8e';
}

.icofont-ui-v-card:before {
  content: '\ec8f';
}

.icofont-ui-video-chat:before {
  content: '\ec90';
}

.icofont-ui-video-message:before {
  content: '\ec91';
}

.icofont-ui-video-play:before {
  content: '\ec92';
}

.icofont-ui-video:before {
  content: '\ec93';
}

.icofont-ui-volume:before {
  content: '\ec94';
}

.icofont-ui-weather:before {
  content: '\ec95';
}

.icofont-ui-wifi:before {
  content: '\ec96';
}

.icofont-ui-zoom-in:before {
  content: '\ec97';
}

.icofont-ui-zoom-out:before {
  content: '\ec98';
}

.icofont-cassette-player:before {
  content: '\ec99';
}

.icofont-cassette:before {
  content: '\ec9a';
}

.icofont-forward:before {
  content: '\ec9b';
}

.icofont-guiter:before {
  content: '\ec9c';
}

.icofont-movie:before {
  content: '\ec9d';
}

.icofont-multimedia:before {
  content: '\ec9e';
}

.icofont-music-alt:before {
  content: '\ec9f';
}

.icofont-music-disk:before {
  content: '\eca0';
}

.icofont-music-note:before {
  content: '\eca1';
}

.icofont-music-notes:before {
  content: '\eca2';
}

.icofont-music:before {
  content: '\eca3';
}

.icofont-mute-volume:before {
  content: '\eca4';
}

.icofont-pause:before {
  content: '\eca5';
}

.icofont-play-alt-1:before {
  content: '\eca6';
}

.icofont-play-alt-2:before {
  content: '\eca7';
}

.icofont-play-alt-3:before {
  content: '\eca8';
}

.icofont-play-pause:before {
  content: '\eca9';
}

.icofont-play:before {
  content: '\ecaa';
}

.icofont-record:before {
  content: '\ecab';
}

.icofont-retro-music-disk:before {
  content: '\ecac';
}

.icofont-rewind:before {
  content: '\ecad';
}

.icofont-song-notes:before {
  content: '\ecae';
}

.icofont-sound-wave-alt:before {
  content: '\ecaf';
}

.icofont-sound-wave:before {
  content: '\ecb0';
}

.icofont-stop:before {
  content: '\ecb1';
}

.icofont-video-alt:before {
  content: '\ecb2';
}

.icofont-video-cam:before {
  content: '\ecb3';
}

.icofont-video-clapper:before {
  content: '\ecb4';
}

.icofont-video:before {
  content: '\ecb5';
}

.icofont-volume-bar:before {
  content: '\ecb6';
}

.icofont-volume-down:before {
  content: '\ecb7';
}

.icofont-volume-mute:before {
  content: '\ecb8';
}

.icofont-volume-off:before {
  content: '\ecb9';
}

.icofont-volume-up:before {
  content: '\ecba';
}

.icofont-youtube-play:before {
  content: '\ecbb';
}

.icofont-2checkout-alt:before {
  content: '\ecbc';
}

.icofont-2checkout:before {
  content: '\ecbd';
}

.icofont-amazon-alt:before {
  content: '\ecbe';
}

.icofont-amazon:before {
  content: '\ecbf';
}

.icofont-american-express-alt:before {
  content: '\ecc0';
}

.icofont-american-express:before {
  content: '\ecc1';
}

.icofont-apple-pay-alt:before {
  content: '\ecc2';
}

.icofont-apple-pay:before {
  content: '\ecc3';
}

.icofont-bank-transfer-alt:before {
  content: '\ecc4';
}

.icofont-bank-transfer:before {
  content: '\ecc5';
}

.icofont-braintree-alt:before {
  content: '\ecc6';
}

.icofont-braintree:before {
  content: '\ecc7';
}

.icofont-cash-on-delivery-alt:before {
  content: '\ecc8';
}

.icofont-cash-on-delivery:before {
  content: '\ecc9';
}

.icofont-diners-club-alt-1:before {
  content: '\ecca';
}

.icofont-diners-club-alt-2:before {
  content: '\eccb';
}

.icofont-diners-club-alt-3:before {
  content: '\eccc';
}

.icofont-diners-club:before {
  content: '\eccd';
}

.icofont-discover-alt:before {
  content: '\ecce';
}

.icofont-discover:before {
  content: '\eccf';
}

.icofont-eway-alt:before {
  content: '\ecd0';
}

.icofont-eway:before {
  content: '\ecd1';
}

.icofont-google-wallet-alt-1:before {
  content: '\ecd2';
}

.icofont-google-wallet-alt-2:before {
  content: '\ecd3';
}

.icofont-google-wallet-alt-3:before {
  content: '\ecd4';
}

.icofont-google-wallet:before {
  content: '\ecd5';
}

.icofont-jcb-alt:before {
  content: '\ecd6';
}

.icofont-jcb:before {
  content: '\ecd7';
}

.icofont-maestro-alt:before {
  content: '\ecd8';
}

.icofont-maestro:before {
  content: '\ecd9';
}

.icofont-mastercard-alt:before {
  content: '\ecda';
}

.icofont-mastercard:before {
  content: '\ecdb';
}

.icofont-payoneer-alt:before {
  content: '\ecdc';
}

.icofont-payoneer:before {
  content: '\ecdd';
}

.icofont-paypal-alt:before {
  content: '\ecde';
}

.icofont-paypal:before {
  content: '\ecdf';
}

.icofont-sage-alt:before {
  content: '\ece0';
}

.icofont-sage:before {
  content: '\ece1';
}

.icofont-skrill-alt:before {
  content: '\ece2';
}

.icofont-skrill:before {
  content: '\ece3';
}

.icofont-stripe-alt:before {
  content: '\ece4';
}

.icofont-stripe:before {
  content: '\ece5';
}

.icofont-visa-alt:before {
  content: '\ece6';
}

.icofont-visa-electron:before {
  content: '\ece7';
}

.icofont-visa:before {
  content: '\ece8';
}

.icofont-western-union-alt:before {
  content: '\ece9';
}

.icofont-western-union:before {
  content: '\ecea';
}

.icofont-boy:before {
  content: '\eceb';
}

.icofont-business-man-alt-1:before {
  content: '\ecec';
}

.icofont-business-man-alt-2:before {
  content: '\eced';
}

.icofont-business-man-alt-3:before {
  content: '\ecee';
}

.icofont-business-man:before {
  content: '\ecef';
}

.icofont-female:before {
  content: '\ecf0';
}

.icofont-funky-man:before {
  content: '\ecf1';
}

.icofont-girl-alt:before {
  content: '\ecf2';
}

.icofont-girl:before {
  content: '\ecf3';
}

.icofont-group:before {
  content: '\ecf4';
}

.icofont-hotel-boy-alt:before {
  content: '\ecf5';
}

.icofont-hotel-boy:before {
  content: '\ecf6';
}

.icofont-kid:before {
  content: '\ecf7';
}

.icofont-man-in-glasses:before {
  content: '\ecf8';
}

.icofont-people:before {
  content: '\ecf9';
}

.icofont-support:before {
  content: '\ecfa';
}

.icofont-user-alt-1:before {
  content: '\ecfb';
}

.icofont-user-alt-2:before {
  content: '\ecfc';
}

.icofont-user-alt-3:before {
  content: '\ecfd';
}

.icofont-user-alt-4:before {
  content: '\ecfe';
}

.icofont-user-alt-5:before {
  content: '\ecff';
}

.icofont-user-alt-6:before {
  content: '\ed00';
}

.icofont-user-alt-7:before {
  content: '\ed01';
}

.icofont-user-female:before {
  content: '\ed02';
}

.icofont-user-male:before {
  content: '\ed03';
}

.icofont-user-suited:before {
  content: '\ed04';
}

.icofont-user:before {
  content: '\ed05';
}

.icofont-users-alt-1:before {
  content: '\ed06';
}

.icofont-users-alt-2:before {
  content: '\ed07';
}

.icofont-users-alt-3:before {
  content: '\ed08';
}

.icofont-users-alt-4:before {
  content: '\ed09';
}

.icofont-users-alt-5:before {
  content: '\ed0a';
}

.icofont-users-alt-6:before {
  content: '\ed0b';
}

.icofont-users-social:before {
  content: '\ed0c';
}

.icofont-users:before {
  content: '\ed0d';
}

.icofont-waiter-alt:before {
  content: '\ed0e';
}

.icofont-waiter:before {
  content: '\ed0f';
}

.icofont-woman-in-glasses:before {
  content: '\ed10';
}

.icofont-search-1:before {
  content: '\ed11';
}

.icofont-search-2:before {
  content: '\ed12';
}

.icofont-search-document:before {
  content: '\ed13';
}

.icofont-search-folder:before {
  content: '\ed14';
}

.icofont-search-job:before {
  content: '\ed15';
}

.icofont-search-map:before {
  content: '\ed16';
}

.icofont-search-property:before {
  content: '\ed17';
}

.icofont-search-restaurant:before {
  content: '\ed18';
}

.icofont-search-stock:before {
  content: '\ed19';
}

.icofont-search-user:before {
  content: '\ed1a';
}

.icofont-search:before {
  content: '\ed1b';
}

.icofont-500px:before {
  content: '\ed1c';
}

.icofont-aim:before {
  content: '\ed1d';
}

.icofont-badoo:before {
  content: '\ed1e';
}

.icofont-baidu-tieba:before {
  content: '\ed1f';
}

.icofont-bbm-messenger:before {
  content: '\ed20';
}

.icofont-bebo:before {
  content: '\ed21';
}

.icofont-behance:before {
  content: '\ed22';
}

.icofont-blogger:before {
  content: '\ed23';
}

.icofont-bootstrap:before {
  content: '\ed24';
}

.icofont-brightkite:before {
  content: '\ed25';
}

.icofont-cloudapp:before {
  content: '\ed26';
}

.icofont-concrete5:before {
  content: '\ed27';
}

.icofont-delicious:before {
  content: '\ed28';
}

.icofont-designbump:before {
  content: '\ed29';
}

.icofont-designfloat:before {
  content: '\ed2a';
}

.icofont-deviantart:before {
  content: '\ed2b';
}

.icofont-digg:before {
  content: '\ed2c';
}

.icofont-dotcms:before {
  content: '\ed2d';
}

.icofont-dribbble:before {
  content: '\ed2e';
}

.icofont-dribble:before {
  content: '\ed2f';
}

.icofont-dropbox:before {
  content: '\ed30';
}

.icofont-ebuddy:before {
  content: '\ed31';
}

.icofont-ello:before {
  content: '\ed32';
}

.icofont-ember:before {
  content: '\ed33';
}

.icofont-envato:before {
  content: '\ed34';
}

.icofont-evernote:before {
  content: '\ed35';
}

.icofont-facebook-messenger:before {
  content: '\ed36';
}

.icofont-facebook:before {
  content: '\ed37';
}

.icofont-feedburner:before {
  content: '\ed38';
}

.icofont-flikr:before {
  content: '\ed39';
}

.icofont-folkd:before {
  content: '\ed3a';
}

.icofont-foursquare:before {
  content: '\ed3b';
}

.icofont-friendfeed:before {
  content: '\ed3c';
}

.icofont-ghost:before {
  content: '\ed3d';
}

.icofont-github:before {
  content: '\ed3e';
}

.icofont-gnome:before {
  content: '\ed3f';
}

.icofont-google-buzz:before {
  content: '\ed40';
}

.icofont-google-hangouts:before {
  content: '\ed41';
}

.icofont-google-map:before {
  content: '\ed42';
}

.icofont-google-plus:before {
  content: '\ed43';
}

.icofont-google-talk:before {
  content: '\ed44';
}

.icofont-hype-machine:before {
  content: '\ed45';
}

.icofont-instagram:before {
  content: '\ed46';
}

.icofont-kakaotalk:before {
  content: '\ed47';
}

.icofont-kickstarter:before {
  content: '\ed48';
}

.icofont-kik:before {
  content: '\ed49';
}

.icofont-kiwibox:before {
  content: '\ed4a';
}

.icofont-line-messenger:before {
  content: '\ed4b';
}

.icofont-line:before {
  content: '\ed4c';
}

.icofont-linkedin:before {
  content: '\ed4d';
}

.icofont-linux-mint:before {
  content: '\ed4e';
}

.icofont-live-messenger:before {
  content: '\ed4f';
}

.icofont-livejournal:before {
  content: '\ed50';
}

.icofont-magento:before {
  content: '\ed51';
}

.icofont-meetme:before {
  content: '\ed52';
}

.icofont-meetup:before {
  content: '\ed53';
}

.icofont-mixx:before {
  content: '\ed54';
}

.icofont-newsvine:before {
  content: '\ed55';
}

.icofont-nimbuss:before {
  content: '\ed56';
}

.icofont-odnoklassniki:before {
  content: '\ed57';
}

.icofont-opencart:before {
  content: '\ed58';
}

.icofont-oscommerce:before {
  content: '\ed59';
}

.icofont-pandora:before {
  content: '\ed5a';
}

.icofont-photobucket:before {
  content: '\ed5b';
}

.icofont-picasa:before {
  content: '\ed5c';
}

.icofont-pinterest:before {
  content: '\ed5d';
}

.icofont-prestashop:before {
  content: '\ed5e';
}

.icofont-qik:before {
  content: '\ed5f';
}

.icofont-qq:before {
  content: '\ed60';
}

.icofont-readernaut:before {
  content: '\ed61';
}

.icofont-reddit:before {
  content: '\ed62';
}

.icofont-renren:before {
  content: '\ed63';
}

.icofont-rss:before {
  content: '\ed64';
}

.icofont-shopify:before {
  content: '\ed65';
}

.icofont-silverstripe:before {
  content: '\ed66';
}

.icofont-skype:before {
  content: '\ed67';
}

.icofont-slack:before {
  content: '\ed68';
}

.icofont-slashdot:before {
  content: '\ed69';
}

.icofont-slidshare:before {
  content: '\ed6a';
}

.icofont-smugmug:before {
  content: '\ed6b';
}

.icofont-snapchat:before {
  content: '\ed6c';
}

.icofont-soundcloud:before {
  content: '\ed6d';
}

.icofont-spotify:before {
  content: '\ed6e';
}

.icofont-stack-exchange:before {
  content: '\ed6f';
}

.icofont-stack-overflow:before {
  content: '\ed70';
}

.icofont-steam:before {
  content: '\ed71';
}

.icofont-stumbleupon:before {
  content: '\ed72';
}

.icofont-tagged:before {
  content: '\ed73';
}

.icofont-technorati:before {
  content: '\ed74';
}

.icofont-telegram:before {
  content: '\ed75';
}

.icofont-tinder:before {
  content: '\ed76';
}

.icofont-trello:before {
  content: '\ed77';
}

.icofont-tumblr:before {
  content: '\ed78';
}

.icofont-twitch:before {
  content: '\ed79';
}

.icofont-twitter:before {
  content: '\ed7a';
}

.icofont-typo3:before {
  content: '\ed7b';
}

.icofont-ubercart:before {
  content: '\ed7c';
}

.icofont-viber:before {
  content: '\ed7d';
}

.icofont-viddler:before {
  content: '\ed7e';
}

.icofont-vimeo:before {
  content: '\ed7f';
}

.icofont-vine:before {
  content: '\ed80';
}

.icofont-virb:before {
  content: '\ed81';
}

.icofont-virtuemart:before {
  content: '\ed82';
}

.icofont-vk:before {
  content: '\ed83';
}

.icofont-wechat:before {
  content: '\ed84';
}

.icofont-weibo:before {
  content: '\ed85';
}

.icofont-whatsapp:before {
  content: '\ed86';
}

.icofont-xing:before {
  content: '\ed87';
}

.icofont-yahoo:before {
  content: '\ed88';
}

.icofont-yelp:before {
  content: '\ed89';
}

.icofont-youku:before {
  content: '\ed8a';
}

.icofont-youtube:before {
  content: '\ed8b';
}

.icofont-zencart:before {
  content: '\ed8c';
}

.icofont-badminton-birdie:before {
  content: '\ed8d';
}

.icofont-baseball:before {
  content: '\ed8e';
}

.icofont-baseballer:before {
  content: '\ed8f';
}

.icofont-basketball-hoop:before {
  content: '\ed90';
}

.icofont-basketball:before {
  content: '\ed91';
}

.icofont-billiard-ball:before {
  content: '\ed92';
}

.icofont-boot-alt-1:before {
  content: '\ed93';
}

.icofont-boot-alt-2:before {
  content: '\ed94';
}

.icofont-boot:before {
  content: '\ed95';
}

.icofont-bowling-alt:before {
  content: '\ed96';
}

.icofont-bowling:before {
  content: '\ed97';
}

.icofont-canoe:before {
  content: '\ed98';
}

.icofont-cheer-leader:before {
  content: '\ed99';
}

.icofont-climbing:before {
  content: '\ed9a';
}

.icofont-corner:before {
  content: '\ed9b';
}

.icofont-field-alt:before {
  content: '\ed9c';
}

.icofont-field:before {
  content: '\ed9d';
}

.icofont-football-alt:before {
  content: '\ed9e';
}

.icofont-football-american:before {
  content: '\ed9f';
}

.icofont-football:before {
  content: '\eda0';
}

.icofont-foul:before {
  content: '\eda1';
}

.icofont-goal-keeper:before {
  content: '\eda2';
}

.icofont-goal:before {
  content: '\eda3';
}

.icofont-golf-alt:before {
  content: '\eda4';
}

.icofont-golf-bag:before {
  content: '\eda5';
}

.icofont-golf-cart:before {
  content: '\eda6';
}

.icofont-golf-field:before {
  content: '\eda7';
}

.icofont-golf:before {
  content: '\eda8';
}

.icofont-golfer:before {
  content: '\eda9';
}

.icofont-helmet:before {
  content: '\edaa';
}

.icofont-hockey-alt:before {
  content: '\edab';
}

.icofont-hockey:before {
  content: '\edac';
}

.icofont-ice-skate:before {
  content: '\edad';
}

.icofont-jersey-alt:before {
  content: '\edae';
}

.icofont-jersey:before {
  content: '\edaf';
}

.icofont-jumping:before {
  content: '\edb0';
}

.icofont-kick:before {
  content: '\edb1';
}

.icofont-leg:before {
  content: '\edb2';
}

.icofont-match-review:before {
  content: '\edb3';
}

.icofont-medal-sport:before {
  content: '\edb4';
}

.icofont-offside:before {
  content: '\edb5';
}

.icofont-olympic-logo:before {
  content: '\edb6';
}

.icofont-olympic:before {
  content: '\edb7';
}

.icofont-padding:before {
  content: '\edb8';
}

.icofont-penalty-card:before {
  content: '\edb9';
}

.icofont-racer:before {
  content: '\edba';
}

.icofont-racing-car:before {
  content: '\edbb';
}

.icofont-racing-flag-alt:before {
  content: '\edbc';
}

.icofont-racing-flag:before {
  content: '\edbd';
}

.icofont-racings-wheel:before {
  content: '\edbe';
}

.icofont-referee:before {
  content: '\edbf';
}

.icofont-refree-jersey:before {
  content: '\edc0';
}

.icofont-result-sport:before {
  content: '\edc1';
}

.icofont-rugby-ball:before {
  content: '\edc2';
}

.icofont-rugby-player:before {
  content: '\edc3';
}

.icofont-rugby:before {
  content: '\edc4';
}

.icofont-runner-alt-1:before {
  content: '\edc5';
}

.icofont-runner-alt-2:before {
  content: '\edc6';
}

.icofont-runner:before {
  content: '\edc7';
}

.icofont-score-board:before {
  content: '\edc8';
}

.icofont-skiing-man:before {
  content: '\edc9';
}

.icofont-skydiving-goggles:before {
  content: '\edca';
}

.icofont-snow-mobile:before {
  content: '\edcb';
}

.icofont-steering:before {
  content: '\edcc';
}

.icofont-stopwatch:before {
  content: '\edcd';
}

.icofont-substitute:before {
  content: '\edce';
}

.icofont-swimmer:before {
  content: '\edcf';
}

.icofont-table-tennis:before {
  content: '\edd0';
}

.icofont-team-alt:before {
  content: '\edd1';
}

.icofont-team:before {
  content: '\edd2';
}

.icofont-tennis-player:before {
  content: '\edd3';
}

.icofont-tennis:before {
  content: '\edd4';
}

.icofont-tracking:before {
  content: '\edd5';
}

.icofont-trophy-alt:before {
  content: '\edd6';
}

.icofont-trophy:before {
  content: '\edd7';
}

.icofont-volleyball-alt:before {
  content: '\edd8';
}

.icofont-volleyball-fire:before {
  content: '\edd9';
}

.icofont-volleyball:before {
  content: '\edda';
}

.icofont-water-bottle:before {
  content: '\eddb';
}

.icofont-whistle-alt:before {
  content: '\eddc';
}

.icofont-whistle:before {
  content: '\eddd';
}

.icofont-win-trophy:before {
  content: '\edde';
}

.icofont-align-center:before {
  content: '\eddf';
}

.icofont-align-left:before {
  content: '\ede0';
}

.icofont-align-right:before {
  content: '\ede1';
}

.icofont-all-caps:before {
  content: '\ede2';
}

.icofont-bold:before {
  content: '\ede3';
}

.icofont-brush:before {
  content: '\ede4';
}

.icofont-clip-board:before {
  content: '\ede5';
}

.icofont-code-alt:before {
  content: '\ede6';
}

.icofont-color-bucket:before {
  content: '\ede7';
}

.icofont-color-picker:before {
  content: '\ede8';
}

.icofont-copy-invert:before {
  content: '\ede9';
}

.icofont-copy:before {
  content: '\edea';
}

.icofont-cut:before {
  content: '\edeb';
}

.icofont-delete-alt:before {
  content: '\edec';
}

.icofont-edit-alt:before {
  content: '\eded';
}

.icofont-eraser-alt:before {
  content: '\edee';
}

.icofont-font:before {
  content: '\edef';
}

.icofont-heading:before {
  content: '\edf0';
}

.icofont-indent:before {
  content: '\edf1';
}

.icofont-italic-alt:before {
  content: '\edf2';
}

.icofont-italic:before {
  content: '\edf3';
}

.icofont-justify-all:before {
  content: '\edf4';
}

.icofont-justify-center:before {
  content: '\edf5';
}

.icofont-justify-left:before {
  content: '\edf6';
}

.icofont-justify-right:before {
  content: '\edf7';
}

.icofont-link-broken:before {
  content: '\edf8';
}

.icofont-outdent:before {
  content: '\edf9';
}

.icofont-paper-clip:before {
  content: '\edfa';
}

.icofont-paragraph:before {
  content: '\edfb';
}

.icofont-pin:before {
  content: '\edfc';
}

.icofont-printer:before {
  content: '\edfd';
}

.icofont-redo:before {
  content: '\edfe';
}

.icofont-rotation:before {
  content: '\edff';
}

.icofont-save:before {
  content: '\ee00';
}

.icofont-small-cap:before {
  content: '\ee01';
}

.icofont-strike-through:before {
  content: '\ee02';
}

.icofont-sub-listing:before {
  content: '\ee03';
}

.icofont-subscript:before {
  content: '\ee04';
}

.icofont-superscript:before {
  content: '\ee05';
}

.icofont-table:before {
  content: '\ee06';
}

.icofont-text-height:before {
  content: '\ee07';
}

.icofont-text-width:before {
  content: '\ee08';
}

.icofont-trash:before {
  content: '\ee09';
}

.icofont-underline:before {
  content: '\ee0a';
}

.icofont-undo:before {
  content: '\ee0b';
}

.icofont-air-balloon:before {
  content: '\ee0c';
}

.icofont-airplane-alt:before {
  content: '\ee0d';
}

.icofont-airplane:before {
  content: '\ee0e';
}

.icofont-articulated-truck:before {
  content: '\ee0f';
}

.icofont-auto-mobile:before {
  content: '\ee10';
}

.icofont-auto-rickshaw:before {
  content: '\ee11';
}

.icofont-bicycle-alt-1:before {
  content: '\ee12';
}

.icofont-bicycle-alt-2:before {
  content: '\ee13';
}

.icofont-bicycle:before {
  content: '\ee14';
}

.icofont-bus-alt-1:before {
  content: '\ee15';
}

.icofont-bus-alt-2:before {
  content: '\ee16';
}

.icofont-bus-alt-3:before {
  content: '\ee17';
}

.icofont-bus:before {
  content: '\ee18';
}

.icofont-cab:before {
  content: '\ee19';
}

.icofont-cable-car:before {
  content: '\ee1a';
}

.icofont-car-alt-1:before {
  content: '\ee1b';
}

.icofont-car-alt-2:before {
  content: '\ee1c';
}

.icofont-car-alt-3:before {
  content: '\ee1d';
}

.icofont-car-alt-4:before {
  content: '\ee1e';
}

.icofont-car:before {
  content: '\ee1f';
}

.icofont-delivery-time:before {
  content: '\ee20';
}

.icofont-fast-delivery:before {
  content: '\ee21';
}

.icofont-fire-truck-alt:before {
  content: '\ee22';
}

.icofont-fire-truck:before {
  content: '\ee23';
}

.icofont-free-delivery:before {
  content: '\ee24';
}

.icofont-helicopter:before {
  content: '\ee25';
}

.icofont-motor-bike-alt:before {
  content: '\ee26';
}

.icofont-motor-bike:before {
  content: '\ee27';
}

.icofont-motor-biker:before {
  content: '\ee28';
}

.icofont-oil-truck:before {
  content: '\ee29';
}

.icofont-rickshaw:before {
  content: '\ee2a';
}

.icofont-rocket-alt-1:before {
  content: '\ee2b';
}

.icofont-rocket-alt-2:before {
  content: '\ee2c';
}

.icofont-rocket:before {
  content: '\ee2d';
}

.icofont-sail-boat-alt-1:before {
  content: '\ee2e';
}

.icofont-sail-boat-alt-2:before {
  content: '\ee2f';
}

.icofont-sail-boat:before {
  content: '\ee30';
}

.icofont-scooter:before {
  content: '\ee31';
}

.icofont-sea-plane:before {
  content: '\ee32';
}

.icofont-ship-alt:before {
  content: '\ee33';
}

.icofont-ship:before {
  content: '\ee34';
}

.icofont-speed-boat:before {
  content: '\ee35';
}

.icofont-taxi:before {
  content: '\ee36';
}

.icofont-tractor:before {
  content: '\ee37';
}

.icofont-train-line:before {
  content: '\ee38';
}

.icofont-train-steam:before {
  content: '\ee39';
}

.icofont-tram:before {
  content: '\ee3a';
}

.icofont-truck-alt:before {
  content: '\ee3b';
}

.icofont-truck-loaded:before {
  content: '\ee3c';
}

.icofont-truck:before {
  content: '\ee3d';
}

.icofont-van-alt:before {
  content: '\ee3e';
}

.icofont-van:before {
  content: '\ee3f';
}

.icofont-yacht:before {
  content: '\ee40';
}

.icofont-5-star-hotel:before {
  content: '\ee41';
}

.icofont-air-ticket:before {
  content: '\ee42';
}

.icofont-beach-bed:before {
  content: '\ee43';
}

.icofont-beach:before {
  content: '\ee44';
}

.icofont-camping-vest:before {
  content: '\ee45';
}

.icofont-direction-sign:before {
  content: '\ee46';
}

.icofont-hill-side:before {
  content: '\ee47';
}

.icofont-hill:before {
  content: '\ee48';
}

.icofont-hotel:before {
  content: '\ee49';
}

.icofont-island-alt:before {
  content: '\ee4a';
}

.icofont-island:before {
  content: '\ee4b';
}

.icofont-sandals-female:before {
  content: '\ee4c';
}

.icofont-sandals-male:before {
  content: '\ee4d';
}

.icofont-travelling:before {
  content: '\ee4e';
}

.icofont-breakdown:before {
  content: '\ee4f';
}

.icofont-celsius:before {
  content: '\ee50';
}

.icofont-clouds:before {
  content: '\ee51';
}

.icofont-cloudy:before {
  content: '\ee52';
}

.icofont-dust:before {
  content: '\ee53';
}

.icofont-eclipse:before {
  content: '\ee54';
}

.icofont-fahrenheit:before {
  content: '\ee55';
}

.icofont-forest-fire:before {
  content: '\ee56';
}

.icofont-full-night:before {
  content: '\ee57';
}

.icofont-full-sunny:before {
  content: '\ee58';
}

.icofont-hail-night:before {
  content: '\ee59';
}

.icofont-hail-rainy-night:before {
  content: '\ee5a';
}

.icofont-hail-rainy-sunny:before {
  content: '\ee5b';
}

.icofont-hail-rainy:before {
  content: '\ee5c';
}

.icofont-hail-sunny:before {
  content: '\ee5d';
}

.icofont-hail-thunder-night:before {
  content: '\ee5e';
}

.icofont-hail-thunder-sunny:before {
  content: '\ee5f';
}

.icofont-hail-thunder:before {
  content: '\ee60';
}

.icofont-hail:before {
  content: '\ee61';
}

.icofont-hill-night:before {
  content: '\ee62';
}

.icofont-hill-sunny:before {
  content: '\ee63';
}

.icofont-hurricane:before {
  content: '\ee64';
}

.icofont-meteor:before {
  content: '\ee65';
}

.icofont-night:before {
  content: '\ee66';
}

.icofont-rainy-night:before {
  content: '\ee67';
}

.icofont-rainy-sunny:before {
  content: '\ee68';
}

.icofont-rainy-thunder:before {
  content: '\ee69';
}

.icofont-rainy:before {
  content: '\ee6a';
}

.icofont-snow-alt:before {
  content: '\ee6b';
}

.icofont-snow-flake:before {
  content: '\ee6c';
}

.icofont-snow-temp:before {
  content: '\ee6d';
}

.icofont-snow:before {
  content: '\ee6e';
}

.icofont-snowy-hail:before {
  content: '\ee6f';
}

.icofont-snowy-night-hail:before {
  content: '\ee70';
}

.icofont-snowy-night-rainy:before {
  content: '\ee71';
}

.icofont-snowy-night:before {
  content: '\ee72';
}

.icofont-snowy-rainy:before {
  content: '\ee73';
}

.icofont-snowy-sunny-hail:before {
  content: '\ee74';
}

.icofont-snowy-sunny-rainy:before {
  content: '\ee75';
}

.icofont-snowy-sunny:before {
  content: '\ee76';
}

.icofont-snowy-thunder-night:before {
  content: '\ee77';
}

.icofont-snowy-thunder-sunny:before {
  content: '\ee78';
}

.icofont-snowy-thunder:before {
  content: '\ee79';
}

.icofont-snowy-windy-night:before {
  content: '\ee7a';
}

.icofont-snowy-windy-sunny:before {
  content: '\ee7b';
}

.icofont-snowy-windy:before {
  content: '\ee7c';
}

.icofont-snowy:before {
  content: '\ee7d';
}

.icofont-sun-alt:before {
  content: '\ee7e';
}

.icofont-sun-rise:before {
  content: '\ee7f';
}

.icofont-sun-set:before {
  content: '\ee80';
}

.icofont-sun:before {
  content: '\ee81';
}

.icofont-sunny-day-temp:before {
  content: '\ee82';
}

.icofont-sunny:before {
  content: '\ee83';
}

.icofont-thunder-light:before {
  content: '\ee84';
}

.icofont-tornado:before {
  content: '\ee85';
}

.icofont-umbrella-alt:before {
  content: '\ee86';
}

.icofont-umbrella:before {
  content: '\ee87';
}

.icofont-volcano:before {
  content: '\ee88';
}

.icofont-wave:before {
  content: '\ee89';
}

.icofont-wind-scale-0:before {
  content: '\ee8a';
}

.icofont-wind-scale-1:before {
  content: '\ee8b';
}

.icofont-wind-scale-10:before {
  content: '\ee8c';
}

.icofont-wind-scale-11:before {
  content: '\ee8d';
}

.icofont-wind-scale-12:before {
  content: '\ee8e';
}

.icofont-wind-scale-2:before {
  content: '\ee8f';
}

.icofont-wind-scale-3:before {
  content: '\ee90';
}

.icofont-wind-scale-4:before {
  content: '\ee91';
}

.icofont-wind-scale-5:before {
  content: '\ee92';
}

.icofont-wind-scale-6:before {
  content: '\ee93';
}

.icofont-wind-scale-7:before {
  content: '\ee94';
}

.icofont-wind-scale-8:before {
  content: '\ee95';
}

.icofont-wind-scale-9:before {
  content: '\ee96';
}

.icofont-wind-waves:before {
  content: '\ee97';
}

.icofont-wind:before {
  content: '\ee98';
}

.icofont-windy-hail:before {
  content: '\ee99';
}

.icofont-windy-night:before {
  content: '\ee9a';
}

.icofont-windy-raining:before {
  content: '\ee9b';
}

.icofont-windy-sunny:before {
  content: '\ee9c';
}

.icofont-windy-thunder-raining:before {
  content: '\ee9d';
}

.icofont-windy-thunder:before {
  content: '\ee9e';
}

.icofont-windy:before {
  content: '\ee9f';
}

.icofont-addons:before {
  content: '\eea0';
}

.icofont-address-book:before {
  content: '\eea1';
}

.icofont-adjust:before {
  content: '\eea2';
}

.icofont-alarm:before {
  content: '\eea3';
}

.icofont-anchor:before {
  content: '\eea4';
}

.icofont-archive:before {
  content: '\eea5';
}

.icofont-at:before {
  content: '\eea6';
}

.icofont-attachment:before {
  content: '\eea7';
}

.icofont-audio:before {
  content: '\eea8';
}

.icofont-automation:before {
  content: '\eea9';
}

.icofont-badge:before {
  content: '\eeaa';
}

.icofont-bag-alt:before {
  content: '\eeab';
}

.icofont-bag:before {
  content: '\eeac';
}

.icofont-ban:before {
  content: '\eead';
}

.icofont-bar-code:before {
  content: '\eeae';
}

.icofont-bars:before {
  content: '\eeaf';
}

.icofont-basket:before {
  content: '\eeb0';
}

.icofont-battery-empty:before {
  content: '\eeb1';
}

.icofont-battery-full:before {
  content: '\eeb2';
}

.icofont-battery-half:before {
  content: '\eeb3';
}

.icofont-battery-low:before {
  content: '\eeb4';
}

.icofont-beaker:before {
  content: '\eeb5';
}

.icofont-beard:before {
  content: '\eeb6';
}

.icofont-bed:before {
  content: '\eeb7';
}

.icofont-bell:before {
  content: '\eeb8';
}

.icofont-beverage:before {
  content: '\eeb9';
}

.icofont-bill:before {
  content: '\eeba';
}

.icofont-bin:before {
  content: '\eebb';
}

.icofont-binary:before {
  content: '\eebc';
}

.icofont-binoculars:before {
  content: '\eebd';
}

.icofont-bluetooth:before {
  content: '\eebe';
}

.icofont-bomb:before {
  content: '\eebf';
}

.icofont-book-mark:before {
  content: '\eec0';
}

.icofont-box:before {
  content: '\eec1';
}

.icofont-briefcase:before {
  content: '\eec2';
}

.icofont-broken:before {
  content: '\eec3';
}

.icofont-bucket:before {
  content: '\eec4';
}

.icofont-bucket1:before {
  content: '\eec5';
}

.icofont-bucket2:before {
  content: '\eec6';
}

.icofont-bug:before {
  content: '\eec7';
}

.icofont-building:before {
  content: '\eec8';
}

.icofont-bulb-alt:before {
  content: '\eec9';
}

.icofont-bullet:before {
  content: '\eeca';
}

.icofont-bullhorn:before {
  content: '\eecb';
}

.icofont-bullseye:before {
  content: '\eecc';
}

.icofont-calendar:before {
  content: '\eecd';
}

.icofont-camera-alt:before {
  content: '\eece';
}

.icofont-camera:before {
  content: '\eecf';
}

.icofont-card:before {
  content: '\eed0';
}

.icofont-cart-alt:before {
  content: '\eed1';
}

.icofont-cart:before {
  content: '\eed2';
}

.icofont-cc:before {
  content: '\eed3';
}

.icofont-charging:before {
  content: '\eed4';
}

.icofont-chat:before {
  content: '\eed5';
}

.icofont-check-alt:before {
  content: '\eed6';
}

.icofont-check-circled:before {
  content: '\eed7';
}

.icofont-check:before {
  content: '\eed8';
}

.icofont-checked:before {
  content: '\eed9';
}

.icofont-children-care:before {
  content: '\eeda';
}

.icofont-clip:before {
  content: '\eedb';
}

.icofont-clock-time:before {
  content: '\eedc';
}

.icofont-close-circled:before {
  content: '\eedd';
}

.icofont-close-line-circled:before {
  content: '\eede';
}

.icofont-close-line-squared-alt:before {
  content: '\eedf';
}

.icofont-close-line-squared:before {
  content: '\eee0';
}

.icofont-close-line:before {
  content: '\eee1';
}

.icofont-close-squared-alt:before {
  content: '\eee2';
}

.icofont-close-squared:before {
  content: '\eee3';
}

.icofont-close:before {
  content: '\eee4';
}

.icofont-cloud-download:before {
  content: '\eee5';
}

.icofont-cloud-refresh:before {
  content: '\eee6';
}

.icofont-cloud-upload:before {
  content: '\eee7';
}

.icofont-cloud:before {
  content: '\eee8';
}

.icofont-code-not-allowed:before {
  content: '\eee9';
}

.icofont-code:before {
  content: '\eeea';
}

.icofont-comment:before {
  content: '\eeeb';
}

.icofont-compass-alt:before {
  content: '\eeec';
}

.icofont-compass:before {
  content: '\eeed';
}

.icofont-computer:before {
  content: '\eeee';
}

.icofont-connection:before {
  content: '\eeef';
}

.icofont-console:before {
  content: '\eef0';
}

.icofont-contacts:before {
  content: '\eef1';
}

.icofont-contrast:before {
  content: '\eef2';
}

.icofont-copyright:before {
  content: '\eef3';
}

.icofont-credit-card:before {
  content: '\eef4';
}

.icofont-crop:before {
  content: '\eef5';
}

.icofont-crown:before {
  content: '\eef6';
}

.icofont-cube:before {
  content: '\eef7';
}

.icofont-cubes:before {
  content: '\eef8';
}

.icofont-dashboard-web:before {
  content: '\eef9';
}

.icofont-dashboard:before {
  content: '\eefa';
}

.icofont-data:before {
  content: '\eefb';
}

.icofont-database-add:before {
  content: '\eefc';
}

.icofont-database-locked:before {
  content: '\eefd';
}

.icofont-database-remove:before {
  content: '\eefe';
}

.icofont-database:before {
  content: '\eeff';
}

.icofont-delete:before {
  content: '\ef00';
}

.icofont-diamond:before {
  content: '\ef01';
}

.icofont-dice-multiple:before {
  content: '\ef02';
}

.icofont-dice:before {
  content: '\ef03';
}

.icofont-disc:before {
  content: '\ef04';
}

.icofont-diskette:before {
  content: '\ef05';
}

.icofont-document-folder:before {
  content: '\ef06';
}

.icofont-download-alt:before {
  content: '\ef07';
}

.icofont-download:before {
  content: '\ef08';
}

.icofont-downloaded:before {
  content: '\ef09';
}

.icofont-drag:before {
  content: '\ef0a';
}

.icofont-drag1:before {
  content: '\ef0b';
}

.icofont-drag2:before {
  content: '\ef0c';
}

.icofont-drag3:before {
  content: '\ef0d';
}

.icofont-earth:before {
  content: '\ef0e';
}

.icofont-ebook:before {
  content: '\ef0f';
}

.icofont-edit:before {
  content: '\ef10';
}

.icofont-eject:before {
  content: '\ef11';
}

.icofont-email:before {
  content: '\ef12';
}

.icofont-envelope-open:before {
  content: '\ef13';
}

.icofont-envelope:before {
  content: '\ef14';
}

.icofont-eraser:before {
  content: '\ef15';
}

.icofont-error:before {
  content: '\ef16';
}

.icofont-excavator:before {
  content: '\ef17';
}

.icofont-exchange:before {
  content: '\ef18';
}

.icofont-exclamation-circle:before {
  content: '\ef19';
}

.icofont-exclamation-square:before {
  content: '\ef1a';
}

.icofont-exclamation-tringle:before {
  content: '\ef1b';
}

.icofont-exclamation:before {
  content: '\ef1c';
}

.icofont-exit:before {
  content: '\ef1d';
}

.icofont-expand:before {
  content: '\ef1e';
}

.icofont-external-link:before {
  content: '\ef1f';
}

.icofont-external:before {
  content: '\ef20';
}

.icofont-eye-alt:before {
  content: '\ef21';
}

.icofont-eye-blocked:before {
  content: '\ef22';
}

.icofont-eye-dropper:before {
  content: '\ef23';
}

.icofont-eye:before {
  content: '\ef24';
}

.icofont-favourite:before {
  content: '\ef25';
}

.icofont-fax:before {
  content: '\ef26';
}

.icofont-file-fill:before {
  content: '\ef27';
}

.icofont-film:before {
  content: '\ef28';
}

.icofont-filter:before {
  content: '\ef29';
}

.icofont-fire-alt:before {
  content: '\ef2a';
}

.icofont-fire-burn:before {
  content: '\ef2b';
}

.icofont-fire:before {
  content: '\ef2c';
}

.icofont-flag-alt-1:before {
  content: '\ef2d';
}

.icofont-flag-alt-2:before {
  content: '\ef2e';
}

.icofont-flag:before {
  content: '\ef2f';
}

.icofont-flame-torch:before {
  content: '\ef30';
}

.icofont-flash-light:before {
  content: '\ef31';
}

.icofont-flash:before {
  content: '\ef32';
}

.icofont-flask:before {
  content: '\ef33';
}

.icofont-focus:before {
  content: '\ef34';
}

.icofont-folder-open:before {
  content: '\ef35';
}

.icofont-folder:before {
  content: '\ef36';
}

.icofont-foot-print:before {
  content: '\ef37';
}

.icofont-garbage:before {
  content: '\ef38';
}

.icofont-gear-alt:before {
  content: '\ef39';
}

.icofont-gear:before {
  content: '\ef3a';
}

.icofont-gears:before {
  content: '\ef3b';
}

.icofont-gift:before {
  content: '\ef3c';
}

.icofont-glass:before {
  content: '\ef3d';
}

.icofont-globe:before {
  content: '\ef3e';
}

.icofont-graffiti:before {
  content: '\ef3f';
}

.icofont-grocery:before {
  content: '\ef40';
}

.icofont-hand:before {
  content: '\ef41';
}

.icofont-hanger:before {
  content: '\ef42';
}

.icofont-hard-disk:before {
  content: '\ef43';
}

.icofont-heart-alt:before {
  content: '\ef44';
}

.icofont-heart:before {
  content: '\ef45';
}

.icofont-history:before {
  content: '\ef46';
}

.icofont-home:before {
  content: '\ef47';
}

.icofont-horn:before {
  content: '\ef48';
}

.icofont-hour-glass:before {
  content: '\ef49';
}

.icofont-id:before {
  content: '\ef4a';
}

.icofont-image:before {
  content: '\ef4b';
}

.icofont-inbox:before {
  content: '\ef4c';
}

.icofont-infinite:before {
  content: '\ef4d';
}

.icofont-info-circle:before {
  content: '\ef4e';
}

.icofont-info-square:before {
  content: '\ef4f';
}

.icofont-info:before {
  content: '\ef50';
}

.icofont-institution:before {
  content: '\ef51';
}

.icofont-interface:before {
  content: '\ef52';
}

.icofont-invisible:before {
  content: '\ef53';
}

.icofont-jacket:before {
  content: '\ef54';
}

.icofont-jar:before {
  content: '\ef55';
}

.icofont-jewlery:before {
  content: '\ef56';
}

.icofont-karate:before {
  content: '\ef57';
}

.icofont-key-hole:before {
  content: '\ef58';
}

.icofont-key:before {
  content: '\ef59';
}

.icofont-label:before {
  content: '\ef5a';
}

.icofont-lamp:before {
  content: '\ef5b';
}

.icofont-layers:before {
  content: '\ef5c';
}

.icofont-layout:before {
  content: '\ef5d';
}

.icofont-leaf:before {
  content: '\ef5e';
}

.icofont-leaflet:before {
  content: '\ef5f';
}

.icofont-learn:before {
  content: '\ef60';
}

.icofont-lego:before {
  content: '\ef61';
}

.icofont-lens:before {
  content: '\ef62';
}

.icofont-letter:before {
  content: '\ef63';
}

.icofont-letterbox:before {
  content: '\ef64';
}

.icofont-library:before {
  content: '\ef65';
}

.icofont-license:before {
  content: '\ef66';
}

.icofont-life-bouy:before {
  content: '\ef67';
}

.icofont-life-buoy:before {
  content: '\ef68';
}

.icofont-life-jacket:before {
  content: '\ef69';
}

.icofont-life-ring:before {
  content: '\ef6a';
}

.icofont-light-bulb:before {
  content: '\ef6b';
}

.icofont-lighter:before {
  content: '\ef6c';
}

.icofont-lightning-ray:before {
  content: '\ef6d';
}

.icofont-like:before {
  content: '\ef6e';
}

.icofont-line-height:before {
  content: '\ef6f';
}

.icofont-link-alt:before {
  content: '\ef70';
}

.icofont-link:before {
  content: '\ef71';
}

.icofont-list:before {
  content: '\ef72';
}

.icofont-listening:before {
  content: '\ef73';
}

.icofont-listine-dots:before {
  content: '\ef74';
}

.icofont-listing-box:before {
  content: '\ef75';
}

.icofont-listing-number:before {
  content: '\ef76';
}

.icofont-live-support:before {
  content: '\ef77';
}

.icofont-location-arrow:before {
  content: '\ef78';
}

.icofont-location-pin:before {
  content: '\ef79';
}

.icofont-lock:before {
  content: '\ef7a';
}

.icofont-login:before {
  content: '\ef7b';
}

.icofont-logout:before {
  content: '\ef7c';
}

.icofont-lollipop:before {
  content: '\ef7d';
}

.icofont-long-drive:before {
  content: '\ef7e';
}

.icofont-look:before {
  content: '\ef7f';
}

.icofont-loop:before {
  content: '\ef80';
}

.icofont-luggage:before {
  content: '\ef81';
}

.icofont-lunch:before {
  content: '\ef82';
}

.icofont-lungs:before {
  content: '\ef83';
}

.icofont-magic-alt:before {
  content: '\ef84';
}

.icofont-magic:before {
  content: '\ef85';
}

.icofont-magnet:before {
  content: '\ef86';
}

.icofont-mail-box:before {
  content: '\ef87';
}

.icofont-mail:before {
  content: '\ef88';
}

.icofont-male:before {
  content: '\ef89';
}

.icofont-map-pins:before {
  content: '\ef8a';
}

.icofont-map:before {
  content: '\ef8b';
}

.icofont-maximize:before {
  content: '\ef8c';
}

.icofont-measure:before {
  content: '\ef8d';
}

.icofont-medicine:before {
  content: '\ef8e';
}

.icofont-mega-phone:before {
  content: '\ef8f';
}

.icofont-megaphone-alt:before {
  content: '\ef90';
}

.icofont-megaphone:before {
  content: '\ef91';
}

.icofont-memorial:before {
  content: '\ef92';
}

.icofont-memory-card:before {
  content: '\ef93';
}

.icofont-mic-mute:before {
  content: '\ef94';
}

.icofont-mic:before {
  content: '\ef95';
}

.icofont-military:before {
  content: '\ef96';
}

.icofont-mill:before {
  content: '\ef97';
}

.icofont-minus-circle:before {
  content: '\ef98';
}

.icofont-minus-square:before {
  content: '\ef99';
}

.icofont-minus:before {
  content: '\ef9a';
}

.icofont-mobile-phone:before {
  content: '\ef9b';
}

.icofont-molecule:before {
  content: '\ef9c';
}

.icofont-money:before {
  content: '\ef9d';
}

.icofont-moon:before {
  content: '\ef9e';
}

.icofont-mop:before {
  content: '\ef9f';
}

.icofont-muffin:before {
  content: '\efa0';
}

.icofont-mustache:before {
  content: '\efa1';
}

.icofont-navigation-menu:before {
  content: '\efa2';
}

.icofont-navigation:before {
  content: '\efa3';
}

.icofont-network-tower:before {
  content: '\efa4';
}

.icofont-network:before {
  content: '\efa5';
}

.icofont-news:before {
  content: '\efa6';
}

.icofont-newspaper:before {
  content: '\efa7';
}

.icofont-no-smoking:before {
  content: '\efa8';
}

.icofont-not-allowed:before {
  content: '\efa9';
}

.icofont-notebook:before {
  content: '\efaa';
}

.icofont-notepad:before {
  content: '\efab';
}

.icofont-notification:before {
  content: '\efac';
}

.icofont-numbered:before {
  content: '\efad';
}

.icofont-opposite:before {
  content: '\efae';
}

.icofont-optic:before {
  content: '\efaf';
}

.icofont-options:before {
  content: '\efb0';
}

.icofont-package:before {
  content: '\efb1';
}

.icofont-page:before {
  content: '\efb2';
}

.icofont-paint:before {
  content: '\efb3';
}

.icofont-paper-plane:before {
  content: '\efb4';
}

.icofont-paperclip:before {
  content: '\efb5';
}

.icofont-papers:before {
  content: '\efb6';
}

.icofont-pay:before {
  content: '\efb7';
}

.icofont-penguin-linux:before {
  content: '\efb8';
}

.icofont-pestle:before {
  content: '\efb9';
}

.icofont-phone-circle:before {
  content: '\efba';
}

.icofont-phone:before {
  content: '\efbb';
}

.icofont-picture:before {
  content: '\efbc';
}

.icofont-pine:before {
  content: '\efbd';
}

.icofont-pixels:before {
  content: '\efbe';
}

.icofont-plugin:before {
  content: '\efbf';
}

.icofont-plus-circle:before {
  content: '\efc0';
}

.icofont-plus-square:before {
  content: '\efc1';
}

.icofont-plus:before {
  content: '\efc2';
}

.icofont-polygonal:before {
  content: '\efc3';
}

.icofont-power:before {
  content: '\efc4';
}

.icofont-price:before {
  content: '\efc5';
}

.icofont-print:before {
  content: '\efc6';
}

.icofont-puzzle:before {
  content: '\efc7';
}

.icofont-qr-code:before {
  content: '\efc8';
}

.icofont-queen:before {
  content: '\efc9';
}

.icofont-question-circle:before {
  content: '\efca';
}

.icofont-question-square:before {
  content: '\efcb';
}

.icofont-question:before {
  content: '\efcc';
}

.icofont-quote-left:before {
  content: '\efcd';
}

.icofont-quote-right:before {
  content: '\efce';
}

.icofont-random:before {
  content: '\efcf';
}

.icofont-recycle:before {
  content: '\efd0';
}

.icofont-refresh:before {
  content: '\efd1';
}

.icofont-repair:before {
  content: '\efd2';
}

.icofont-reply-all:before {
  content: '\efd3';
}

.icofont-reply:before {
  content: '\efd4';
}

.icofont-resize:before {
  content: '\efd5';
}

.icofont-responsive:before {
  content: '\efd6';
}

.icofont-retweet:before {
  content: '\efd7';
}

.icofont-road:before {
  content: '\efd8';
}

.icofont-robot:before {
  content: '\efd9';
}

.icofont-royal:before {
  content: '\efda';
}

.icofont-rss-feed:before {
  content: '\efdb';
}

.icofont-safety:before {
  content: '\efdc';
}

.icofont-sale-discount:before {
  content: '\efdd';
}

.icofont-satellite:before {
  content: '\efde';
}

.icofont-send-mail:before {
  content: '\efdf';
}

.icofont-server:before {
  content: '\efe0';
}

.icofont-settings-alt:before {
  content: '\efe1';
}

.icofont-settings:before {
  content: '\efe2';
}

.icofont-share-alt:before {
  content: '\efe3';
}

.icofont-share-boxed:before {
  content: '\efe4';
}

.icofont-share:before {
  content: '\efe5';
}

.icofont-shield:before {
  content: '\efe6';
}

.icofont-shopping-cart:before {
  content: '\efe7';
}

.icofont-sign-in:before {
  content: '\efe8';
}

.icofont-sign-out:before {
  content: '\efe9';
}

.icofont-signal:before {
  content: '\efea';
}

.icofont-site-map:before {
  content: '\efeb';
}

.icofont-smart-phone:before {
  content: '\efec';
}

.icofont-soccer:before {
  content: '\efed';
}

.icofont-sort-alt:before {
  content: '\efee';
}

.icofont-sort:before {
  content: '\efef';
}

.icofont-space:before {
  content: '\eff0';
}

.icofont-spanner:before {
  content: '\eff1';
}

.icofont-speech-comments:before {
  content: '\eff2';
}

.icofont-speed-meter:before {
  content: '\eff3';
}

.icofont-spinner-alt-1:before {
  content: '\eff4';
}

.icofont-spinner-alt-2:before {
  content: '\eff5';
}

.icofont-spinner-alt-3:before {
  content: '\eff6';
}

.icofont-spinner-alt-4:before {
  content: '\eff7';
}

.icofont-spinner-alt-5:before {
  content: '\eff8';
}

.icofont-spinner-alt-6:before {
  content: '\eff9';
}

.icofont-spinner:before {
  content: '\effa';
}

.icofont-spreadsheet:before {
  content: '\effb';
}

.icofont-square:before {
  content: '\effc';
}

.icofont-ssl-security:before {
  content: '\effd';
}

.icofont-star-alt-1:before {
  content: '\effe';
}

.icofont-star-alt-2:before {
  content: '\efff';
}

.icofont-star:before {
  content: '\f000';
}

.icofont-street-view:before {
  content: '\f001';
}

.icofont-support-faq:before {
  content: '\f002';
}

.icofont-tack-pin:before {
  content: '\f003';
}

.icofont-tag:before {
  content: '\f004';
}

.icofont-tags:before {
  content: '\f005';
}

.icofont-tasks-alt:before {
  content: '\f006';
}

.icofont-tasks:before {
  content: '\f007';
}

.icofont-telephone:before {
  content: '\f008';
}

.icofont-telescope:before {
  content: '\f009';
}

.icofont-terminal:before {
  content: '\f00a';
}

.icofont-thumbs-down:before {
  content: '\f00b';
}

.icofont-thumbs-up:before {
  content: '\f00c';
}

.icofont-tick-boxed:before {
  content: '\f00d';
}

.icofont-tick-mark:before {
  content: '\f00e';
}

.icofont-ticket:before {
  content: '\f00f';
}

.icofont-tie:before {
  content: '\f010';
}

.icofont-toggle-off:before {
  content: '\f011';
}

.icofont-toggle-on:before {
  content: '\f012';
}

.icofont-tools-alt-2:before {
  content: '\f013';
}

.icofont-tools:before {
  content: '\f014';
}

.icofont-touch:before {
  content: '\f015';
}

.icofont-traffic-light:before {
  content: '\f016';
}

.icofont-transparent:before {
  content: '\f017';
}

.icofont-tree:before {
  content: '\f018';
}

.icofont-unique-idea:before {
  content: '\f019';
}

.icofont-unlock:before {
  content: '\f01a';
}

.icofont-unlocked:before {
  content: '\f01b';
}

.icofont-upload-alt:before {
  content: '\f01c';
}

.icofont-upload:before {
  content: '\f01d';
}

.icofont-usb-drive:before {
  content: '\f01e';
}

.icofont-usb:before {
  content: '\f01f';
}

.icofont-vector-path:before {
  content: '\f020';
}

.icofont-verification-check:before {
  content: '\f021';
}

.icofont-wall-clock:before {
  content: '\f022';
}

.icofont-wall:before {
  content: '\f023';
}

.icofont-wallet:before {
  content: '\f024';
}

.icofont-warning-alt:before {
  content: '\f025';
}

.icofont-warning:before {
  content: '\f026';
}

.icofont-water-drop:before {
  content: '\f027';
}

.icofont-web:before {
  content: '\f028';
}

.icofont-wheelchair:before {
  content: '\f029';
}

.icofont-wifi-alt:before {
  content: '\f02a';
}

.icofont-wifi:before {
  content: '\f02b';
}

.icofont-world:before {
  content: '\f02c';
}

.icofont-zigzag:before {
  content: '\f02d';
}

.icofont-zipped:before {
  content: '\f02e';
}

.icofont-xs {
  font-size: 0.5em;
}

.icofont-sm {
  font-size: 0.75em;
}

.icofont-md {
  font-size: 1.25em;
}

.icofont-lg {
  font-size: 1.5em;
}

.icofont-1x {
  font-size: 1em;
}

.icofont-2x {
  font-size: 2em;
}

.icofont-3x {
  font-size: 3em;
}

.icofont-4x {
  font-size: 4em;
}

.icofont-5x {
  font-size: 5em;
}

.icofont-6x {
  font-size: 6em;
}

.icofont-7x {
  font-size: 7em;
}

.icofont-8x {
  font-size: 8em;
}

.icofont-9x {
  font-size: 9em;
}

.icofont-10x {
  font-size: 10em;
}

.icofont-fw {
  text-align: center;
  width: 1.25em;
}

.icofont-ul {
  list-style-type: none;
  padding-left: 0;
  margin-left: 0;
}

.icofont-ul > li {
  position: relative;
  line-height: 2em;
}

.icofont-ul > li .icofont {
  display: inline-block;
  vertical-align: middle;
}

.icofont-border {
  border: solid 0.08em #f1f1f1;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.icofont-pull-left {
  float: left;
}

.icofont-pull-right {
  float: right;
}

.icofont.icofont-pull-left {
  margin-right: 0.3em;
}

.icofont.icofont-pull-right {
  margin-left: 0.3em;
}

.icofont-spin {
  -webkit-animation: icofont-spin 2s infinite linear;
  animation: icofont-spin 2s infinite linear;
  display: inline-block;
}

.icofont-pulse {
  -webkit-animation: icofont-spin 1s infinite steps(8);
  animation: icofont-spin 1s infinite steps(8);
  display: inline-block;
}

@-webkit-keyframes icofont-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes icofont-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.icofont-rotate-90 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=1)';
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.icofont-rotate-180 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2)';
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
}

.icofont-rotate-270 {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=3)';
  -webkit-transform: rotate(270deg);
  transform: rotate(270deg);
}

.icofont-flip-horizontal {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)';
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}

.icofont-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
  -webkit-transform: scale(1, -1);
  transform: scale(1, -1);
}

.icofont-flip-horizontal.icofont-flip-vertical {
  -ms-filter: 'progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)';
  -webkit-transform: scale(-1, -1);
  transform: scale(-1, -1);
}

:root .icofont-rotate-90,
:root .icofont-rotate-180,
:root .icofont-rotate-270,
:root .icofont-flip-horizontal,
:root .icofont-flip-vertical {
  -webkit-filter: none;
  filter: none;
  display: inline-block;
}

.icofont-inverse {
  color: #fff;
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active,
.sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}
