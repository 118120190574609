// dropdown-menu
.dropdown-animation {
  opacity: 0;
  transform: translateY(15px);
  -webkit-animation: eliment-animation 0.2s ease-in-out 0.33333s;
  animation: eliment-animation 0.2s ease-in-out 0.33333s;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}

@-webkit-keyframes eliment-animation {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes eliment-animation {
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes ping {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

.lift {
  transition:
    box-shadow 0.25s ease,
    transform 0.25s ease;

  &:focus,
  &:hover {
    box-shadow:
      0 6px 1rem rgba($dark, 0.1),
      0 0.5rem 1rem -0.75rem rgba($dark, 0.1) !important;
    transform: translate3d(0, -3px, 0);
  }
}
