.agent-chat-mood-btn-groups {
  &:hover {
    .agent-chat-mood-btn {
      filter: grayscale(1);
    }
  }

  .agent-chat-mood-btn {
    &:hover {
      filter: grayscale(0);
      transform: scale(1.5);
    }
  }
}
