.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 10vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.resizable {
  position: relative;
  width: fit-content;
  height: 100%;
  border: 1px solid #ccc;
  overflow: hidden;
}

.content {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.resizer {
  position: absolute;
  background-color: #ccc;
  cursor: nwse-resize;
}

.resizer-left-top {
  width: 15px;
  height: 15px;
  top: 0px; /* half of the resizer height */
  left: 0px; /* half of the resizer width */
  border-bottom-right-radius: 50%;
}

.resizer-left-bottom {
  width: 15px;
  height: 15px;
  bottom: 0px; /* half of the resizer height */
  left: 0px; /* half of the resizer width */
  border-top-right-radius: 50%;
}

* {
  scrollbar-width: thin;
  scrollbar-color: #acacac transparent;
}
