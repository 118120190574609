:root {
  --dark-color: #323a45;

  --color-fff: #ffffff;
  --color-100: #f0f0f0;
  --color-200: #eeeeee;
  --color-300: #e0e0e0;
  --color-400: #bdbdbd;
  --color-500: #555555;
  --color-600: #757575;
  --color-700: #616161;
  --color-800: #424242;
  --color-900: #212121;
  --color-000: #000000;

  --border-color: #f0f0f0;
  --card-color: #eef1fa;
  --body-color: #f9fbfd;
  --white-color: #ffffff;
  --sidebar-color: #fcfcfc;
  --text-color: #212529;

  --hc-font-color: #555555;
  --hc-bg-color: #f0f0f0;

  --bg-color: #7f89e133;
  --color-on-primary: #ffffff;
  --color-on-primary-20: #ffffff33;
  --color-on-primary-30: #ffffff4d;
  --color-on-primary-40: #ffffff66;
  --color-on-primary-60: #ffffff99;
  --color-on-primary-80: #ffffffcc;
  --color-primary: #6138ca;
  --color-primary-30: #6138ca4d;
  --color-primary-80: #6138cacc;
  --color-on-surface: #072840;
  --color-surface: #f4f4fe;
  --color-surface-80: #f4f4fecc;
  --color-surface-low: #dcdcf0;
  --color-surface-error: #fee7e0cc;
  --color-on-surface-dim: #516b7e;
  --color-line-light: #3c358733;
  --color-label-yellow: #edcc58;
  --color-label-bluegreen: #78c2ca;
  --color-label-blue: #9eb4ef;
  --color-error: #e82f2f;
  --chat-shadow: 0 10px 30px 0 rgba(213, 179, 235, 0.15);
  --icon-color: #6138ca;
  --color-logo-secondary: #ee612a;
  --color-logo-secondary-60: #eea02a;
  --color-surface-high-80: #f0c37c;
  --bubble-chat: #6940f5;
  --chat-bg-color: #343a400a;

  --bg-chat-body: #F0EEFE;
  --bg-chat-bubble: #DCDCF0;
  --bg-chat-bubble-send: #f9f8ff99;
}

[data-theme='dark'] {
  --dark-color: #323a45;

  --color-fff: #000000;
  --color-100: #212121;
  --color-200: #262727;
  --color-300: #616161;
  --color-400: #757575;
  --color-500: #8f8f8f;
  --color-600: #bdbdbd;
  --color-700: #e0e0e0;
  --color-800: #eeeeee;
  --color-900: #f0f0f0;
  --color-000: #ffffff;

  --border-color: transparent;
  --card-color: linear-gradient(#7135d3, #6522b9, #681bb4, #681ab5);
  --body-color: linear-gradient(#521aae, #592ba3, #6a2893, #6218ac);
  --white-color: #ffffff;
  --sidebar-color: #fcfcfc;
  --text-color: #ebeefa;

  --hc-font-color: #555555;
  --hc-bg-color: #f0f0f0;

  --bg-color: linear-gradient(#521aae, #592ba3, #6a2893, #6218ac);
  --color-surface: #523394;
  --color-surface-80: #523394cc;
  --color-surface-low: #6d53a5;
  --color-on-surface: #ebeefa;
  --color-on-surface-dim: #aeadbe;
  --color-on-primary-60: #1e104699;
  --chat-shadow: 0 10px 30px 0 rgba(110, 88, 171, 0.3);
  --color-surface-error: #f67168e6;
  --color-error: #cf0000;
  --icon-color: #fff;
  --color-logo-secondary: #ec4502;
  --color-logo-secondary-60: #c1803b;
  --color-surface-high-80: #391569;

  [class='theme-indigo'] {
    --primary-color: linear-gradient(#7135d3, #6522b9, #681bb4, #681ab5);
    --secondary-color: #f19828;
    --body-color: #1e1f20;
    --border-color: transparent;
    --card-color: linear-gradient(#220a653a, #220a6563);
    --primary-gradient: linear-gradient(45deg, #f5558d, #ff1843);
  }
}

[class='theme-indigo'] {
  --primary-color: linear-gradient(#622dd3, #651ab1, #6818b1, #6c14b1, #6b23e2);
  --secondary-color: #f19828;

  --chart-color1: #484c7f;
  --chart-color2: #f19828;
  --chart-color3: #f5558d;
  --chart-color4: #a7daff;
  --chart-color5: #9cbf3b;
  --chart-color6: #a0c1b8;
  --chart-color7: #64868e;

  --primary-gradient: linear-gradient(45deg, #f5558d, #ff1843);
}

.secondary-color {
  color: var(--secondary-color);
}
.secondary-color-fill {
  fill: var(--secondary-color);
}
