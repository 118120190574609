.card {
  box-shadow: 0 8px 24px 0 rgba(74, 11, 176, 0.11);
  border-color: var(--border-color);
  background: var(--card-color);
  &.card-callout {
    border-left-width: 0.4rem;
    border-left-color: var(--primary-color);
  }
  .card-header {
    background-color: transparent;
    border-bottom: none;
    color: var(--text-color);
  }
  .card-body {
    color: var(--text-color);
  }
}

.card-header {
  h3 {
    @media only screen and (max-width: 1024px) {
      font-size: 20px;
    }
    @media only screen and (max-width: 767px) {
      font-size: 18px;
    }
  }
}
